import React, { Component } from 'react';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { claimCashSetStep, claimCashSetDataLookUp, claimCashSetDataDetail, claimCashSetCurrentPayment, claimCashStep1Input, claimCashStep3Input, claimCashReset } from '../ClaimCash/Reducer/ClaimCashAction';
import Step from '../../MasterComponents/Step/Step';
import GridCol from '../../MasterComponents/Grid/GridCol';
import GridRow from '../../MasterComponents/Grid/GridRow';
import Dash from '../../MasterComponents/Dash/Dash';
import GridContainer from '../../MasterComponents/Grid/GridContainer';
import ClaimCashLookUp from './ClaimCashLookUp';
import ClaimCashChecks from './ClaimCashChecks';
import ClaimCashForm from './ClaimCashForm';
import ClaimCashApi from './ClaimCashApi';
import VerifyRespondModal from '../Dashboard/Modals/VerifyRespondModal';
import SuccessClaimModal from './Modals/SuccessClaimModal';
import config from '../../config/config';
import ClaimCashDetailModal from './Details/ClaimCashDetailModal';
class ClaimCashBody extends Component {
    //---------------STEP1-------------------------------
    changeHandlerStep1 = (value, name) => {
        const { dispatch, claimCash } = this.props;
        const { step1Input } = claimCash;
        dispatch(claimCashStep1Input({
            ...step1Input,
            [name]: value
        }));
    }

    showRebateAmount = () => {
        const { dispatch, token, claimCash } = this.props;
        const { step1Input } = claimCash;
        const params = {
            merchant: step1Input.merchant.trim(),
            phoneNumer: step1Input.phoneNumer,
            code: step1Input.code.trim()
        }
        dispatch(spinner(true));
        ClaimCashApi.lookUp(params, token).then((res)=> {
            dispatch(spinner(false));
            dispatch(claimCashSetStep(2));
            if(res.data.data.length > 0) {
                dispatch(claimCashSetDataLookUp(res.data.data.map((item, i) => {
                    if (i % 2 !== 0) {
                        return {
                            ...item,
                            isGray : true
                        }
                    }
                    return {
                        ...item,
                        isGray : false
                    }
                })));
            } else {
                dispatch(claimCashSetDataLookUp([]));
            }
        }).catch(function() {
            dispatch(spinner(false));
        });
    }

    //---------------STEP2---------------------------------------------------
    seeDetails = (payment) => {
        const { history, dispatch, token, isIframe } = this.props;
        const params = {
            sfCheckId: payment.sfCheckId
        }
        dispatch(spinner(true));
        dispatch(claimCashSetCurrentPayment(payment));
        ClaimCashApi.checkDetail(params, token).then((res)=> {
            dispatch(spinner(false));
            dispatch(claimCashSetDataDetail(res.data.data));

            if (isIframe) {
                window.open(res.data.data.fileUrl, '_blank');
                return;
            }
            history.push('/dashboard/claim/detail');
            this.goToClaimCash(payment, false);
            if(isMobile) {
                dispatch(showModal(ClaimCashDetailModal, 'idClaimCashDetailModal', {header: payment.timeframe, history, dispatch }));
            }
        }).catch(() => {
            dispatch(spinner(false));
        });
    }

    goToClaimCash = (data, goToNextStep) => {
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        const countryDefault = this.findCountry(data);
        const stateDefault = this.findState(data);
        dispatch(claimCashStep3Input({
            ...step3Input,
            checkId: data.id,
            contactName: data.merchant,
            contactNameDisabled: data.merchant ? true : false,
            bank: data.bank ? data.bank.toString().trim() : '',
            bankDisabled: data.bank ? true : false,
            branch: data.branch ? data.branch.toString().trim() : '',
            branchDisabled: data.branch ? true : false,
            account: data.account ? data.account.toString().trim() : '',
            accountDisabled: data.account ? true : false,
            address: data.address,
            addressDisabled: data.address ? true : false,
            country: data.country,
            countryObj: countryDefault,
            countryDisabled: data.country ? true : false,
            state: data.state,
            stateObj: stateDefault,
            stateDisabled: data.state ? true : false,
            city: data.city,
            cityDisabled: data.address ? true : false,
            bankId: data.bankId ? data.bankId.toString().trim() : '',
            bankIdDisabled: data.bankId ? true : false,
            postcode: data.postcode ? data.postcode.toString().trim() : '',
            postcodeDisabled: data.postcode ? true : false,
            dateBirth: data.dateBirth,
            dateBirthDisabled: data.dateBirth ? true : false
        }));
        if(goToNextStep) {
            dispatch(claimCashSetStep(3));
        }
    }

    //---------------STEP3--------------------------------------------------

    changeHandlerStep3 = (value, name) => {
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        dispatch(claimCashStep3Input({
            ...step3Input,
            [name]: value
        }));
    }

    changeDate = (name, date) => {
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        dispatch(claimCashStep3Input({
            ...step3Input,
            [name]: date
        }));
    }

    editFields = () => {
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        dispatch(claimCashStep3Input({
            ...step3Input,
            contactNameDisabled: false,
            bankDisabled: false,
            branchDisabled: false,
            accountDisabled: false,
            addressDisabled: false,
            countryDisabled: false,
            stateDisabled: false,
            cityDisabled: false,
            postcodeDisabled: false
        }));
    }

    getStates = (objCountry) => {
        if(objCountry) {
            let arrayStates;
            switch (objCountry.value) {
                case 'CA':
                    arrayStates = config.caProvinces
                    break;
                case 'US':
                    arrayStates = config.usStates
                    break;
                default:
                    break;
            }
            const options = arrayStates.map((item) => {
                return {
                    label: item.name,
                    value: item.abbreviation
                }
            });
            return options;
        }
    }

    selectCountry = (objCountry) => {
        const  optionsCountry = this.getStates(objCountry);
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        dispatch(claimCashStep3Input({
            ...step3Input,
            countryObj: objCountry,
            country: objCountry.value,
            stateObj: optionsCountry,
            state: optionsCountry[0].value,
            getStatesCountry: optionsCountry
        }));
    };

    selectState = (objState) => {
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        dispatch(claimCashStep3Input({
            ...step3Input,
            stateObj: objState,
            state: objState.value
        }));
    };

    selectChecked = () => {
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        dispatch(claimCashStep3Input({
            ...step3Input,
            isAuthorized: !step3Input.isAuthorized
        }));
    }

    selectAgreedPayment = () => {
        const { dispatch, claimCash } = this.props;
        const { step3Input } = claimCash;
        dispatch(claimCashStep3Input({
            ...step3Input,
            agreedPayment: !step3Input.agreedPayment
        }));
    }

    findCountry = (data) => {
        if(data.country) {
            const abbrevMap = config.countries.map(s => { return s.abbreviation });
            const index = abbrevMap.indexOf(data.country);
            return {
                label: config.countries[index] ? config.countries[index].name : '', 
                value: config.countries[index] ? config.countries[index].abbreviation : ''
            }
        }
        return null
    }

    findState = (data) => {
        if(data.country && data.state) {
            let arrayStates;
            switch (data.country) {
                case 'CA':
                    arrayStates = config.caProvinces
                    break;
                case 'US':
                    arrayStates = config.usStates
                    break;
                default:
                    break;
            }
            const abbrevMap = arrayStates.map(s => { return s.abbreviation });
            const index = abbrevMap.indexOf(data.state);
            return {
                label: arrayStates[index] ? arrayStates[index].name : '', 
                value: arrayStates[index] ? arrayStates[index].abbreviation : ''
            }
        }
        return null
    }

    submitForm = (brandCode, brandName) => {
        const { dispatch, token, claimCash, firstName, lastName, email} = this.props;
        const { step3Input } = claimCash;
        const params = {
            checkId: step3Input.checkId,
            contactName: step3Input.contactName,
            bank: step3Input.bank,
            branch: step3Input.branch.toString().trim(),
            account: step3Input.account.toString().trim(),
            address: step3Input.address,
            state: step3Input.state,
            postcode: step3Input.postcode.toString().trim(),
            country: step3Input.country,
            city: step3Input.city,
            bankId: step3Input.bankId ? step3Input.bankId.toString().trim() : '' ,
            signedText: step3Input.signedText,
            email: email,
            firstName: firstName,
            lastName: lastName
        }
        dispatch(spinner(true));
        ClaimCashApi.checkRequest(params, token).then((res)=> {
            dispatch(spinner(false));
            if(res.data.data === true) {
                dispatch(showModal(SuccessClaimModal, 'idSuccessClaimModal', {brandCode : brandCode, brandName: brandName }));
                this.resetStates();
                dispatch(claimCashSetStep(1));
            }
        }).catch((error) => {
            dispatch(spinner(false));
            let errorMessage = error.data.data.message;
            dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', { message: errorMessage, button: "Ok"}));
        });
    }

    resetStates = () => {
        const { dispatch } = this.props;
        dispatch(claimCashReset());
    }

    render() {
        const { companyName, companyAbbreviation, history, claimCash, dispatch} = this.props;
        const { steps, dataLookUp, step1Input, step3Input } = claimCash;
        const claimLookUp = {
            steps,
            step1Input,
            changeHandlerStep1: this.changeHandlerStep1,
            showRebateAmount: this.showRebateAmount
        }
        const claimCheckProps = {
            steps,
            dataLookUp,
            goToClaimCash: this.goToClaimCash,
            seeDetails: this.seeDetails,
            history,
            dispatch
        }
        const claimFormProps = {
            steps,
            step3Input,
            changeHandlerStep3: this.changeHandlerStep3,
            changeDate: this.changeDate,
            submitForm: this.submitForm,
            companyBrand: {name:companyName, code:companyAbbreviation},
            selectCountry: this.selectCountry,
            selectState: this.selectState,
            selectChecked: this.selectChecked,
            selectAgreedPayment: this.selectAgreedPayment,
            editFields: this.editFields,
            dispatch
        }

        if(isMobile || this.props.isMobile) {
            return (
                 <GridContainer isFluid={true} className='claim-steps'>
                    <GridRow>
                        <GridCol className='pt-4 steps'>
                            <Step status={steps.step1.done ? 'done' : (steps.step1.status ? 'active' : '')} text={1} style={{width: '2.2em'}} />
                            <Dash className='mx-2' />
                            <Step status={steps.step2.done ? 'done' : (steps.step2.status ? 'active' : '')} text={2} style={{width: '2.2em'}} />
                            <Dash className='mx-2' />
                            <Step status={steps.step3.done ? 'done' : (steps.step3.status ? 'active' : '')} text={3} style={{width: '2.2em'}} /> 
                        </GridCol>
                    </GridRow>
                    <GridRow className='py-5'>
                        { steps.step1.status && 
                            <GridCol>
                                <ClaimCashLookUp {...claimLookUp} />
                            </GridCol>
                        }
                        { steps.step2.status && 
                            <GridCol className='px-0'>
                                <ClaimCashChecks {...claimCheckProps} />
                            </GridCol>
                        }
                        { steps.step3.status && 
                            <GridCol>
                                <ClaimCashForm  {...claimFormProps} />
                            </GridCol>
                        }
                    </GridRow>
                </GridContainer>
            )
        }

        return (
            <GridContainer isFluid={true} className='p-5' >
                <GridRow className='claim-steps'>
                    <GridCol className='steps mt-4 mb-5 px-5'>
                        <Step status={steps.step1.done ? 'done' : (steps.step1.status ? 'active' : '')} text={1} style={{width: '2.2em'}} />
                        <Dash className='mx-5' />
                        <Step status={steps.step2.done ? 'done' : (steps.step2.status ? 'active' : '')} text={2} style={{width: '2.2em'}} />
                        <Dash className='mx-5' />
                        <Step status={steps.step3.done ? 'done' : (steps.step3.status ? 'active' : '')} text={3} style={{width: '2.2em'}} /> 
                    </GridCol>
                </GridRow>
                <GridRow className='pt-3 pb-5'>
                    <GridCol className='px-4 border-right'>
                        <ClaimCashLookUp {...claimLookUp} />
                    </GridCol>
                    <GridCol className='px-4 border-right'>
                        <ClaimCashChecks {...claimCheckProps} />
                    </GridCol>
                    <GridCol className='pl-4'>
                        <ClaimCashForm  {...claimFormProps} />
                    </GridCol>
                </GridRow>
            </GridContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        companyName: state.company.nickname,
        companyAbbreviation: state.company.abbreviation,
        claimCash: state.claimCash
    };
}

export default connect(mapStateToProps)(ClaimCashBody);