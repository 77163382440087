import React, { Component } from 'react';
import '../SignUp.scss';
import queryString from 'query-string';
import { connect } from 'react-redux';
import SignUpForm from '../Forms/SignUpForm';
import SignUpWhitelabelSidebar from './SignUpWhitelabelSidebar';
import { setRegisterFormType, updatePartners, setSegment, setSubsegment } from '../../../config/Company/CompanyActions';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import CompanyApi from '../../../config/Company/CompanyApi';

class SignUpWhitelabelNewForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            lastStep: 1,
            initReferredBy: null,
            loading: true
        };
    };
    componentDidMount() {
        const { registerLinks, dispatch, defaultChannelPartner, location, isCanadaUrl } = this.props;
        const { search } = location;
        const parsed = queryString.parse(search);
        if (parsed.segment) {
          dispatch(setSegment(parsed.segment));
        }
        if (parsed.subsegment) {
          dispatch(setSubsegment(parsed.subsegment));
        }

        if (registerLinks.length > 0) {
            if (isCanadaUrl) {
                // Check if there is a Canadian URl Form
                const canadianDescription = registerLinks.filter(link => link.description === 'register-canada')[0];

                if (canadianDescription) {
                    dispatch(setRegisterFormType(canadianDescription.formType));
                } else {
                    for (let i = 0; i < registerLinks.length; i++) {
                        const elem = registerLinks[i];
                        if (!elem.isExternalDomain) {
                            dispatch(setRegisterFormType(elem.formType));
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < registerLinks.length; i++) {
                    const elem = registerLinks[i];
                    if (!elem.isExternalDomain) {
                        dispatch(setRegisterFormType(elem.formType));
                        break;
                    }
                }
            }
        }
        const channel = this.channelLogo();
        if (defaultChannelPartner) {
            this.setState({
                loading: false,
                initReferredBy: {
                    value: defaultChannelPartner.id,
                    label: defaultChannelPartner.description,
                    isDaSalesRep: true,
                    platformId: defaultChannelPartner.id
                }
            })
        } else if (channel) {
            this.isOnTheList(channel);
        } else {
            this.setState({
                loading: false
            });
        }
    };
    isOnTheList = (name) => {
        const { dispatch } = this.props;
        dispatch(spinner(true));
        CompanyApi.getOne(1, 'partners').then(({data}) => {
            const { partners } = data.data;
            dispatch(spinner(false));
            let referBy = null;
            partners.forEach(c => {
                if (c.abbreviation === name.toLowerCase() && c.type === 'channel') {
                    referBy = {value: c.id, isDaSalesRep: c.salesRepId, label: c.description, platformId: c.channelPlatformId};
                    dispatch(updatePartners(partners, c));
                    dispatch(setRegisterFormType(c.formType));
                }
            });
            this.setState({
                loading: false,
                initReferredBy: referBy
            });
        });
    };
    channelLogo = () => {
        const { search } = this.props.history.location;
        if (search) {
            const qp = queryString.parse(search);
            if (qp.c) {
                return qp.c;
            }
        }
        return null;
    };
    componentDidUpdate(prevProps, prevState) {
        const { action, push, replace } = this.props.history;
        const { urlStep } = this.props.match.params;
        const prevStep = parseInt(prevProps.match.params.urlStep ? prevProps.match.params.urlStep : 1);
        const futureStep = parseInt(urlStep ? urlStep : 1);
        const { registerLinks, dispatch, isCanadaUrl } = this.props;
        const url = isCanadaUrl ? '/register/canada' : `/register/new`;

        if (action === 'POP') {
            if ( ( prevStep < futureStep ) && ( futureStep > this.state.lastStep ) ) {
                this.setState({
                    step: prevStep,
                    lastStep: prevStep > this.state.lastStep ? prevStep : this.state.lastStep
                });
                replace(`${url}/${prevStep}`);
            } else if (futureStep > this.state.lastStep) {
                push(`${url}/${this.state.lastStep}`)
            } else {
                if (futureStep !== this.state.step) {
                    this.setState({
                        step: futureStep,
                        lastStep: futureStep > this.state.lastStep ? futureStep : this.state.lastStep
                    });
                }
            }
        } else {
            if (futureStep !== this.state.step) {
                this.setState({
                    step: futureStep,
                    lastStep: futureStep > this.state.lastStep ? futureStep : this.state.lastStep
                });
            }
        }
        if (registerLinks.length > 0 && !this.channelLogo()) {
            if (isCanadaUrl) {
                // Check if there is a Canadian URl Form
                const canadianDescription = registerLinks.filter(link => link.description === 'register-canada')[0];

                if (canadianDescription) {
                    dispatch(setRegisterFormType(canadianDescription.formType));
                } else {
                    for (let i = 0; i < registerLinks.length; i++) {
                        const elem = registerLinks[i];
                        if (!elem.isExternalDomain) {
                            dispatch(setRegisterFormType(elem.formType));
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < registerLinks.length; i++) {
                    const elem = registerLinks[i];
                    if (!elem.isExternalDomain) {
                        dispatch(setRegisterFormType(elem.formType));
                        break;
                    }
                }
            }
        }
    };
    render() {
        const { history, companyName, formType, companyId, partner, country, isCanadaUrl, signUpSalesRep } = this.props;
        const { step, initReferredBy, loading } = this.state;
        const sidebarProps = {
            history,
            companyName,
            partner,
        };
        const pathRegister = signUpSalesRep ? '/register/salesrep' : '/register/new';
        const formProps = {
            companyId,
            country,
            isPorchDoor: false,
            step,
            maxSteps: companyId === 1 ? 5 : 4,
            formType,
            history,
            initReferredBy,
            title: step === 1 ? 'Step 1: Business and Contact Information' : (step === 2 ? 'Step 2: Payment Information' : ( step === 3 ? 'Step 3: Location Information' : ( step === 4 ? 'Step 4: Distributor Information' : 'Step 5: Terms & Conditions'))),
            pathname: isCanadaUrl ? '/register/canada' : pathRegister
        };
        return (
            <div className='container-signup'>
                <SignUpWhitelabelSidebar {...sidebarProps} />
                {
                    !loading && <SignUpForm {...formProps} signUpSalesRep={signUpSalesRep} />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        isPorchdoorRedirect: state.company.isPorchdoorRedirect,
        companyName: state.company.nickname,
        formType: state.company.registerFormType,
        registerLinks: state.company.registerLinks,
        partner: state.company.partnerSelected,
        defaultChannelPartner: state.company.defaultChannelPartner,
        country: state.company.country
    }
}

export default connect(mapStateToProps)(SignUpWhitelabelNewForm);
