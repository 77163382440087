import React, { Component, Fragment } from 'react'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import { showModal, findModal } from '../../../MasterComponents/Modals/ModalsActions';
import Modal from '../../../MasterComponents/Modals/Modal';
import ModalHeader from '../../../MasterComponents/Modals/ModalHeader';
import ModalBody from '../../../MasterComponents/Modals/ModalBody';
import ModalFooter from '../../../MasterComponents/Modals/ModalFooter';
import Button from '../../../MasterComponents/Button/Button';
import DropDownListSelect from '../../../MasterComponents/DropDownList/DropDownListSelect';
import InputLabel from '../../../MasterComponents/InputLabel/InputLabel';
import VerifyRespondModal from './VerifyRespondModal';
import Input from '../../../MasterComponents/Input/Input';
import DashedBox from '../../../MasterComponents/DashedBox/DashedBox';
import IconUpload from '../../../MasterComponents/Icons/IconUpload';
import config from '../../../config/config';
import GoodNewsModal from './GoodNewsModal';
import DistributorReportsForMapModal from './DistributorReportsForMapModal';
import GridRow from '../../../MasterComponents/Grid/GridRow';
import GridCol from '../../../MasterComponents/Grid/GridCol';
import Checkbox from '../../../MasterComponents/Checkbox/Checkbox';
import LogoExcel from '../../../MasterComponents/Logos/LogoExcel';
import LogoPicture from '../../../MasterComponents/Logos/LogoPicture';
import IconDelete from '../../../MasterComponents/Icons/IconDelete';
import { isMobile } from 'react-device-detect';
import DashboardApi from '../DashboardApi';
import IconCheck from '../../../MasterComponents/Icons/IconCheck';
import { isTextValid, isEmailValid, isPhoneValid } from '../../../MasterComponents/Input/InputValidate';
import { spinner } from '../../../MasterComponents/Spinner/SpinnerActions';
import LogoPdf from '../../../MasterComponents/Logos/LogoPdf';
import ReactGA from 'react-ga';
import TakePictureModal from './TakePictureModal';
import IconAlert from '../../../MasterComponents/Icons/IconAlert';
import LocationApi from '../../Location/LocationApi';
import showEnterCredentials from '../../../MasterComponents/SharedFunctions/showEnterCredentials';
import DistributorNonEntegraModal from '../../SignUp/Modals/DistributorNonEntegraModal';
import DistributorSkyloaderFalseModal from '../../SignUp/Modals/DistributorSkyloaderFalseModal';
import NotificationApi from '../../Notification/NotificationApi';
import { notificationSetCounter, notificationSetData } from '../../Notification/Reducer/NotificationAction';

class ActivateDistributorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: {label: 'Online Ordering', value: 'online', isDisabled: false},
      showUpload: false,
      emailReminder: props.merchantDistributor.emailReminder ? props.merchantDistributor.emailReminder : '',
      phoneReminder: props.merchantDistributor.phoneNumberReminder ? props.merchantDistributor.phoneNumberReminder : '',
      dcnManual: '',
      dcn: [],
      check: [],
      checkAll: false,
      checkOnePlease: false,
      credentials: [],
      credentialsAll: {username: '', password: '', isCheckTermsConditions: false, credentialsAccepted : false},
      fileEmpty: true,
      fileName: '',
      file: null,
      incorrectType: false,
      isImage: false,
      startDate: new Date(),
      endDate: new Date(),
      merchantDistributors: [],
      files: [],
      isClickButton : false,
      isFromEmailNotification: window.location.pathname.includes('/notification/') && window.location.search.includes('token')
    };
    this.showEnterCredentials = showEnterCredentials.bind(this);
  }
  componentDidMount = () => {
    const { merchantDistributor, dispatch } = this.props;
    dispatch(spinner(true));
    if(merchantDistributor.submissionType === 'auto') {
      this.selectOption({label: 'Auto', value: 'auto', isDisabled : false});
    }
    else if (merchantDistributor.submissionType === 'manual') {
      this.selectOption({label: 'Manual Upload', value: 'manual', isDisabled : false });
    }

    const params = {
      distributorId: '['+merchantDistributor.distributorId+']'
    };

    LocationApi.getMerchantDistributor(params).then(({data}) => {
      dispatch(spinner(false));
      const result = data.data.sort((a, b) => a.merchant.nickname.toString().localeCompare(b.merchant.nickname.toString()));
      this.setState({
        merchantDistributors: result,
        dcn: result.map(merDist => (merDist.dcn && merDist.dcn.trim()) || ''),
        credentials: result.map(merDist => {
          return {
            username: '',
            password: '',
            isCheckTermsConditions: false,
            credentialsAccepted : merDist.isActive
          };
        }),
        check: result.map(merDist => {
          return ((merDist.dcn && merDist.dcn.trim()) && merDist.submissionType !== 'manual') || merDist.id === merchantDistributor.id;
        })
      })
    })
  }
  checkHandler = (i) => {
    const {check} = this.state;
    const newValue = !check[i];
    const checkNew = check.map((item, index) => {
      if (i !== index) {
        return item;
      }
      return newValue
    });
    this.setState({
      check: checkNew,
      checkAll: checkNew.every(v => v === true && v.length > 0),
      checkOnePlease: false
    })
  }
  checkAllHandler = (i) => {
    const {check, checkAll} = this.state;
    const newValue = !checkAll;
    this.setState({
      checkAll: newValue,
      check: check.map((item, index) => {
        return newValue
      }),
      checkOnePlease: false
    })
  }
  selectOption = (value) => {
    this.setState({
      dropdown: value,
      showUpload: value.value === 'manual' ? true : false
    });
  };
  oneDcnInvalid = () => {
    const { dcn, check } = this.state;
    return dcn.some((d, i) => check[i] && !this.refs[`dcn-${i}`].isValid());
  };
  atLeastOneCheck = () => {
    const { check } = this.state;
    const result = check.some(c => c === true);
    this.setState({
      checkOnePlease: result
    });
    return result;
  };
  credentialsAccepted = () => {
    const { checkAll, credentialsAll, credentials, check } = this.state;
    this.setState({
      isClickButton : true
    });
    if (checkAll) {
      return credentialsAll.credentialsAccepted;
    } else {
      return check.some((c, i) => c && credentials[i].credentialsAccepted);
    }
  };
  activate = () => {
    const { files, dcn, merchantDistributors, checkAll, credentialsAll, check, dropdown, emailReminder, phoneReminder, credentials, startDate, endDate, dcnManual } = this.state;
    const { emailInput, dcnManualInput } = this.refs;
    const { dispatch, merchantDistributor, refresh, isEdit } = this.props;

    if (dropdown.value === 'online') {
      const merDists = check.map((item, index) => {
        const credential = {
          username: checkAll ? (credentialsAll.username ? credentialsAll.username.toString().trim() : '' ) : (credentials[index].username ? credentials[index].username.toString().trim() : '' ),
          password: checkAll ? (credentialsAll.password ? credentialsAll.password.toString().trim() : ''  ) : (credentials[index].password ? credentials[index].password.toString().trim() : '' )
        }
        if (this.isCredentialsOk(credential)) {
          return {
            check: item,
            id: merchantDistributors[index].id,
            dcn: dcn[index],
            ...credential
          }
        } else {
          return {
            check: item,
            id: merchantDistributors[index].id,
            dcn: dcn[index]
          }
        }
      }).filter(mer => mer.check).map((mer) => {
        const { check, ...fields } = mer;
        return {
          ...fields
        }
      });
      dispatch(spinner(true));
      if (isEdit) {
        ReactGA.event({
          category: 'Edit Distributor',
          action: 'Click Save'
        });
      }
      DashboardApi.activateMerchantDistributor(merDists, dropdown.value).then(({data}) => {
        dispatch(spinner(false));
        findModal(this.props.modals, 'activateDistributorModal').close();

        // Remove Notification
        merDists.forEach(merDist => {
          if (merDist.dcn) {
            // Success Find a notification that matches
            if (this.props.notifications.some(not => not.merchantDistributor.id === merDist.id)) {
              const not = this.props.notifications.filter(not => not.merchantDistributor.id === merDist.id)[0];
              if (not.type === 'failedDcn' || (merDist.username && merDist.password)) {
                NotificationApi.deleteNotification(not.id).then(() => {
                  NotificationApi.getNotifications().then(({data}) => {
                    const noti = data.data;
                    const countNotRead = noti.filter(n => n.isRead === 0).length;
                    dispatch(notificationSetData(noti));
                    dispatch(notificationSetCounter(countNotRead));
                  }).catch(err => {
                    console.error(err);
                  });
                }).catch(err => {
                  console.error(err);
                });
              }
            }
          }
        });
        
        if (this.isSaveOrActivate() === 'Activate'){
          const isNonEntegra = merchantDistributor && merchantDistributor.entegraReportingMethod === 'NON-ENTEGRA DISTRIBUTOR';
          const isSkyloaderAvailable = merchantDistributor && merchantDistributor.skyloaderAvailable;

          if (isNonEntegra) {
            dispatch(showModal(DistributorNonEntegraModal, 'distributorNonEntegraModal'));
          } else if (!isSkyloaderAvailable) {
            dispatch(showModal(DistributorSkyloaderFalseModal, 'distributorSkyloaderFalseModal'));
          } else {
            dispatch(showModal(GoodNewsModal, 'goodNewsModal'));
          }
        }
        refresh();
      }).catch(error => {
          let errorMessage = error.data.data.message;
          dispatch(spinner(false));
          dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', { message: errorMessage, button: "Ok"}));
          refresh();
      });
    } else if (dropdown.value === 'auto') {
      const merDists = check.map((item, index) => {
        return {
          check: item,
          id: merchantDistributors[index].id,
          dcn: dcn[index]
        }
      }).filter(mer => mer.check).map((mer) => {
        const { check, ...fields } = mer;
        return {
          ...fields
        }
      });
      DashboardApi.activateMerchantDistributor(merDists, dropdown.value).then(({data}) => {
        dispatch(spinner(false));
        findModal(this.props.modals, 'activateDistributorModal').close();
        if (this.isSaveOrActivate() === 'Activate'){
          dispatch(showModal(DistributorReportsForMapModal, 'distributorReportsForMapModalId'));
        }
        refresh();
      }).catch(error => {
          let errorMessage = error.data.data.message;
          dispatch(spinner(false));
          dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', { message: errorMessage, button: "Ok"}));
          refresh();
      });

    } else {
      if (emailInput.isValid() && dcnManualInput.isValid()) {
        const phone = isPhoneValid(phoneReminder) ? phoneReminder : null;
        this.setState({
          phoneReminder: phone
        });
        //empty();
        const format = 'YYYY-MM-DD';
        const dateRange = moment(startDate).format(format) + ' - ' + moment(endDate).format(format);
        dispatch(spinner(true));
        const merDistId = merchantDistributor.id;
        if (isEdit) {
          ReactGA.event({
            category: 'Edit Distributor',
            action: 'Click Save'
          });
        }
        DashboardApi.activateMerchantDistributorManual(merDistId, files, dateRange, emailReminder, phone, dcnManual).then(({data}) => {
          dispatch(spinner(false));
          findModal(this.props.modals, 'activateDistributorModal').close();
          if (this.isSaveOrActivate() === 'Activate'){
            dispatch(showModal(GoodNewsModal, 'goodNewsModal'));
          }
          refresh();
        }).catch(error => {
          let errorMessage = error.data.data.message;
          dispatch(spinner(false));
          dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', { message: errorMessage, button: "Ok"}));
          refresh();
        });
      }
    }
  }
  cantClick = () => {
    const { dcn, check, credentials, checkAll, credentialsAll, dropdown, emailReminder } = this.state;
    if (dropdown.value === 'online') {
      for (let i = 0; i < dcn.length; i++) {
        if (check[i] && !isTextValid(dcn[i], 2, 20)) {
          return true;
        }
      }
  
      if (checkAll) {
        if (!this.isCredentialsOk(credentialsAll)) {
          return true;
        }
      }
  
      let atLeastOneCheck = false;
  
      for (let i = 0; i < check.length; i++) {
        const ele = check[i];
        if (ele) {
          if (!this.isCredentialsOk(credentials[i]) && !checkAll) {
            return true;
          }
          atLeastOneCheck = true;
        }
      }
      
      if (!atLeastOneCheck) {
        return true;
      }
      return false;
    } else if (dropdown.value === 'auto') {
      return false;
    } else {
      if (!isEmailValid(emailReminder)) {
        return true;
      }
      return false;
    }
  }
  isCredentialsOk = (credentials) => {
    if (isTextValid(credentials.username, 2, 100) && isTextValid(credentials.password, 2, 100)) {
      return true;
    }
    return false;
  }
  changeHandler = (value, name, e) => {
    this.setState({
      [name]: value
    });
  };
  dcnChangeHandler = (value, i) => {
    const {dcn} = this.state
    this.setState({
      dcn: dcn.map((item, index) => {
        if (index !== i) {
          return item;
        }
        return value
      })
    });
  }
  uploadExcel = () => {
    const { hiddenFileInput } = this.refs;
    hiddenFileInput.click();
  }
  uploadImage = () => {
    // if (isMobile && navigator && navigator.mediaDevices !== undefined) {
    //   this.takePicture();
    // } else {
      const { hiddenFileImageInput } = this.refs;
      hiddenFileImageInput.click();
    // }
  }
  takePicture = () => {
    const { dispatch } = this.props;
    dispatch(showModal(TakePictureModal, 'takePictureModal', {saveSnap: this.saveSnap})); 
  };
  saveSnap = (snap) => {
    this.setState({
      files: [
        ...this.state.files,
        {
          isImage: true,
          isPdf: false,
          isSnap: true,
          incorrectType: false,
          fileName: snap.name,
          file: snap
        }
      ]
    });
    findModal(this.props.modals, "takePictureModal").close();
  };
  selectFile = (inputFileId) => {
    const files = $('#'+ inputFileId).prop('files');
    const excelTypes = ["text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.oasis.opendocument.spreadsheet", "application/pdf"];
    const imageTypes = ["image/jpeg", "image/png"];
    const fileArray = [...this.state.files];
    for (let i = 0; i < files.length; i++){
      const file = files[i];
      if (imageTypes.indexOf(file.type) !== -1 || !file.type || excelTypes.indexOf(file.type) !== -1) {
        file.isImage = imageTypes.indexOf(file.type) !== -1 ? true : false;
        file.isPdf = file.type === 'application/pdf' ? true : false;
        file.incorrectType = false;
        file.fileName = file.name;
        file.file = file;
        fileArray.push(file);
      } else {
        $('#'+ inputFileId).val("");
      }
    }

    this.setState({
      files: fileArray
    })
  }
  clearFile = (index) => {
    const files = [...this.state.files];
    files.splice(index,1);
    this.setState({files : files});
    if (files.length === 0){
      $('#excel-upload').val("")
      $('#img-upload').val("")
    }
  }
  changeDate = (name, date) => {
    this.setState({
      [name]: date
    });
  }
  getCorrectIconAll = () => {
    const { credentialsAll } = this.state;
    if (this.isCredentialsOk(credentialsAll)) {
      if (isMobile) {
        return (
          <GridCol className='p-0'>
            <div className='check mobile'>
              <IconCheck size={11} color={config.colors.rbWhite} />
            </div>
          </GridCol>
        )
      }
      return (
        <GridCol className='pl-0' style={{maxWidth: '40px'}}>
          <div className='check all'>
            <IconCheck size={11} color={config.colors.rbWhite} />
          </div>
        </GridCol>
      );
    } else {
      if (isMobile) {
        return (
          <GridCol className='pl-0'>
            <IconAlert size={24}/>
          </GridCol>
        )
      }
      return (
        <GridCol style={{maxWidth: '40px', padding: 0, paddingBottom: '7px'}}>
          <IconAlert size={24}/>
        </GridCol>
      );
    }
  };
  getCorrectIcon = (i) => {
    const { credentials, merchantDistributors } = this.state;
    if ((this.isCredentialsOk(credentials[i]) || credentials[i].credentialsAccepted) && merchantDistributors[i].status !== 'error') {
      if (isMobile) {
        return (
          <GridCol className='p-0'>
            <div className='check mobile'>
              <IconCheck size={11} color={config.colors.rbWhite} />
            </div>
          </GridCol>
        );
      }
      return (
        <GridCol className='p-0' style={{maxWidth: '25px'}}>
          <div className='check'>
            <IconCheck size={11} color={config.colors.rbWhite} />
          </div>
        </GridCol>
      );
    } else {
      if (isMobile) {
        if (merchantDistributors[i].status === 'error') {
          setTimeout(() => {
            $(`[data-toggle="error_tooltip_${i}"]`).tooltip();
          }, 200);
          return (
            <GridCol className='p-0'>
              <span className='clickable' data-toggle={`error_tooltip_${i}`} data-placement='bottom' title={merchantDistributors[i].statusMessage === 'Processing...' ? 'Pending...' : merchantDistributors[i].statusMessage}>
                <IconAlert size={24}  color={config.colors.rbRedLight} />
              </span>
            </GridCol>
          );
        }
        return (
          <GridCol className='p-0'>
            <IconAlert size={24} />
          </GridCol>
        );
      }
      if (merchantDistributors[i].status === 'error') {
        setTimeout(() => {
          $(`[data-toggle="error_tooltip_${i}"]`).tooltip();
        }, 200);
        return (
          <GridCol style={{maxWidth: '25px', padding: 0, paddingBottom: '7px'}}>
            <span className='clickable' data-toggle={`error_tooltip_${i}`} data-placement='bottom' title={merchantDistributors[i].statusMessage === 'Processing...' ? 'Pending...' : merchantDistributors[i].statusMessage}>
              <IconAlert size={24} color={config.colors.rbRedLight} />
            </span>
          </GridCol>
        );
      }
      return (
        <GridCol style={{maxWidth: '25px', padding: 0, paddingBottom: '7px'}}>
          <IconAlert size={24} />
        </GridCol>
      );
    }
  };
  cancel = () => {
    const { modals } = this.props;
    findModal(modals, 'activateDistributorModal').close();
  };
  isSaveOrActivate = () => {
    const { merchantDistributor } = this.props;
    const { merchantDistributors, credentials, dcn, check, credentialsAll, checkAll } = this.state;

    const isBackup = merchantDistributor && (merchantDistributor.statusType === 'backupSuggested' || merchantDistributor.statusType === 'backupRequired');
    const activateText = isBackup ? 'Save' : 'Activate';

    if (checkAll) {
      return credentialsAll.credentialsAccepted ? activateText : 'Save';
    } else {
      const all = merchantDistributors.map((merDist, i) => {
        return {
          check: check[i],
          dcn: dcn[i],
          credentials: credentials[i]
        }
      }).filter(c => c.check);
      
      const canActivate = all.every(c => c.dcn.length > 2 && this.isCredentialsOk(c.credentials));

      return canActivate ? activateText : 'Save';
    }
  };

  render() {
    const { isEdit, merchantDistributor } = this.props;
    const { merchantDistributors, dropdown, showUpload, dcn, check, checkAll, checkOnePlease, startDate, endDate, isClickButton, isFromEmailNotification } = this.state;
    const options = [{
      label: 'Online Ordering', value: 'online'
    }];
    const isBackup = merchantDistributor && (merchantDistributor.statusType === 'backupSuggested' || merchantDistributor.statusType === 'backupRequired');
    const { files } = this.state;
    const fileArray = files ? files.map( (file,index) => (
      <div key={index}>
      <Fragment >
          { file.isImage ? <LogoPicture style={{width: '30px'}} /> : ( file.isPdf ? <LogoPdf style={{width: '30px'}} /> : <LogoExcel style={{width: '30px'}} />) } {file.fileName}
          <span onClick={() => this.clearFile(index)} className='float-right mt-1 clickable'><IconDelete color={config.colors.rbDarkGrey} /></span>
      </Fragment>
      </div>
    )) : null;

    const imgDashedText = isMobile && navigator && navigator.mediaDevices !== undefined ? 'Take a Picture' : 'Upload Image';
    const addAnotherText =  isMobile && navigator && navigator.mediaDevices !== undefined ? 'Add Another Picture' : 'Add Another Image';

    const addNewFile = fileArray && fileArray.length > 0 ? 
    <div className="w-100 d-flex justify-content-between">
      <div style={{flex: '0 1 49%'}}>
          <Button onClick={this.uploadExcel} variant='primary' size='lg-fs-md' className='w-100' text='Add another CSV/Excel File' />
      </div>
      <div style={{flex: '0 1 49%'}}>
          <Button onClick={this.uploadImage} variant='primary' size='lg-fs-md' className='w-100' text={addAnotherText} />
      </div>
    </div> : null;

    
    if (isMobile) {
      return (
        <Modal id='activateDistributorModal' backdrop={false} centered={isFromEmailNotification}>
          <ModalHeader closeButton={false} blueHeader>{ isEdit ? 'Reconnect Distributor' : 'Connect Distributor'}</ModalHeader>
          <ModalBody>
            <GridRow>
              <GridCol>
                <InputLabel>Purchase History Submission Type *</InputLabel>
                <DropDownListSelect defaultValue={options[0]}
                  name='submissionType' ref='submissionType'
                  value={dropdown}
                  isSearchable={false}
                  onChange={this.selectOption} options={options}
                  isDisabled={(isBackup || dropdown.isDisabled) && merchantDistributor.statusType !== 'auto'}
                />
              </GridCol>
            </GridRow>
            { showUpload ?
            <Fragment>
              <GridRow className='mt-3'>
                <GridCol>
                  <InputLabel>Email for Reminders *</InputLabel>
                  <Input ref='emailInput' value={this.state.emailReminder || ''} type='email' name='emailReminder' placeholder='Email' validate onChange={this.changeHandler} />
                </GridCol>
              </GridRow>
              <GridRow className='mt-3'>
                <GridCol >
                  <InputLabel>Distributor Account # (DCN) *</InputLabel>
                  <Input ref='dcnManualInput' value={this.state.dcnManual || ''} name='dcnManual' placeholder='DCN' min={2} max={20} validate onChange={this.changeHandler} />
                </GridCol>
              </GridRow>
              <GridRow className='mt-3'>
                <GridCol>
                  <InputLabel>Mobile Phone for Text Reminders (optional)</InputLabel>
                  <Input ref='phoneInput' value={this.state.phoneReminder} type='tel' name='phoneReminder' placeholder='Phone' onChange={this.changeHandler} />
                </GridCol>
              </GridRow>
            </Fragment>
            :
            <Fragment>
            { merchantDistributors.length > 1 ? 
            <GridRow align='center'>          
              <GridCol className={checkOnePlease ? 'text-size-14 text-red font-weight-bold' : 'font-weight-bold text-size-14'}>
                All Locations
              </GridCol>            
              <GridCol style={{maxWidth:'55px'}}>
                <Checkbox name='all' value={checkAll} onChange={this.checkAllHandler}/>
              </GridCol>             
            </GridRow>
            : null }
            { checkAll ?
              <GridRow align='center'>
                <GridCol size={10}>
                  { dropdown.value !== 'auto' ?
                    <Button onClick={this.showEnterCredentials.bind(this, 'all', merchantDistributors[0], dcn)} variant='primary' size='smd w-100' text='Connect' />
                    : null
                  }
                </GridCol>
                { this.getCorrectIconAll() }
              </GridRow>
            : null }
            {merchantDistributors.map((merDist, i) => (
              <Fragment key={i}>
                <GridRow align='center'>
                  <GridCol className={checkOnePlease ? 'text-size-14 text-red font-weight-bold' : 'font-weight-bold text-size-14'}>
                    {merDist.merchant.nickname}
                  </GridCol>
                  <GridCol style={{maxWidth:'55px'}}>
                    <Checkbox name={i} value={check[i]} onChange={this.checkHandler} />
                  </GridCol>
                </GridRow>
                {check[i] ?
                  <Fragment>
                    <GridRow>
                      <GridCol>
                        <InputLabel>Distributor Account # (DCN) *</InputLabel>
                        <Input value={dcn[i]} name={i} ref={'dcn-'+i} placeholder='DCN' min={2} max={20} validate onChange={this.dcnChangeHandler} />
                        {
                          (check[i] && (dcn[i] === '' && isClickButton)) ?
                            <span className='text-red' style={{fontSize:'16px'}}>Enter DCN to activate distributor </span>
                          :  
                            <span > &nbsp; </span>
                        }
                      </GridCol>
                    </GridRow>
                    {!checkAll ? 
                      <GridRow align='center' className='mt-3'>
                        <GridCol size={10}>
                          { dropdown.value !== 'auto' ?
                            <Button onClick={this.showEnterCredentials.bind(this, i, merDist, [dcn[i]])} variant='primary' size='smd w-100' text='Connect' />
                            : null
                          }
                        </GridCol>
                        {  dropdown.value !== 'auto' && this.getCorrectIcon(i) }
                      </GridRow>
                    : null}
                  </Fragment>
                :null}
              </Fragment>
            ))}
            </Fragment>
            }
          {
            showUpload ?
            <GridRow className='mt-3'>
              <input ref='hiddenFileInput' className='d-none' onChange={() => this.selectFile('excel-upload')} type="file" multiple="multiple" id='excel-upload' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx, application/vnd.oasis.opendocument.spreadsheet, application/pdf" />
              <input ref='hiddenFileImageInput' className='d-none' onChange={() => this.selectFile('img-upload')} type="file" multiple="multiple" id='img-upload' accept="image/jpeg,image/x-png" />
              <GridCol className='text-size-14'>
                { 
                  fileArray.length === 0 ?
                  <div className="d-flex">
                    <DashedBox onClick={this.uploadExcel} textClassName='text-size-12' className={'m-0 clickable w-50'} height='90px'>
                      <IconUpload color={config.colors.rbDarkerGrey} /> 
                      &nbsp; {'Upload pdf, excel or csv file'}</DashedBox>

                    <DashedBox onClick={this.uploadImage} textClassName='text-size-12' className={'m-0 clickable w-50'} height='90px'>
                      <IconUpload color={config.colors.rbDarkerGrey} /> 
                      &nbsp; {imgDashedText}</DashedBox>
                </div>
                  
                  :
                  fileArray
                }
                { 
                  fileArray.length > 0 ?
                  <Fragment>
                    <GridRow className='mt-4'>
                      <GridCol size={6}>
                        <InputLabel>File Start Date</InputLabel>
                        <DatePicker name='startDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={startDate} dateFormat="MM/dd/yyyy" maxDate={new Date()} onChange={this.changeDate.bind(this, 'startDate')} withPortal />
                      </GridCol>
                      <GridCol size={6}>
                        <InputLabel>File End Date</InputLabel>
                        <DatePicker name='endDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={endDate} dateFormat="MM/dd/yyyy" minDate={startDate} maxDate={new Date()} onChange={this.changeDate.bind(this, 'endDate')} withPortal />
                      </GridCol>
                    </GridRow>
                  </Fragment>
                  :
                  null
                }

              </GridCol>
            </GridRow>
            : null
          }
          <GridRow className='mt-4'>
            <div className="w-100">
              <div className="row px-3 pb-2">
                {!isFromEmailNotification &&
                  <GridCol size={6}>
                    <Button variant='white' onClick={this.cancel} size='md w-100' text={'Cancel'} />
                  </GridCol>}
                <GridCol size={isFromEmailNotification ? 12 : 6}>
                  <Button disabled={isBackup ? false : (dropdown.value === 'online' ? false : this.cantClick())} variant='primary' onClick={this.activate} size='md w-100' text={isBackup ? 'PROVIDE BACKUP' : (isEdit ? 'Save' : this.isSaveOrActivate())} />
                  <Button onClick={this.takePicture} variant='primary' size='lg-fs-md' className='d-none takePictureButton' text='Take Picture' />
                </GridCol>
              </div>
              {addNewFile}
            </div>
          </GridRow>
          </ModalBody>
        </Modal>
      );
    }
    return (
      <Modal size='lg' id='activateDistributorModal' backdrop={false}>
        <ModalHeader closeButton={false}>{ isEdit ? 'Reconnect Distributor' : 'Connect Distributor'}</ModalHeader>
        <ModalBody>
          <GridRow className='mb-2'>
            <GridCol size={6}>
              <InputLabel>Purchase History Submission Type *</InputLabel>
              <DropDownListSelect defaultValue={options[0]}
                name='submissionType' ref='submissionType'
                isSearchable={false}
                value={dropdown}
                onChange={this.selectOption} options={options}
                isDisabled={(isBackup || dropdown.isDisabled) && merchantDistributor.statusType !== 'auto'}
              />
            </GridCol>
            { showUpload ?
              <GridCol size={6}>
                <InputLabel>Distributor Account # (DCN) *</InputLabel>
                <Input ref='dcnManualInput' value={this.state.dcnManual || ''} name='dcnManual' placeholder='DCN' min={2} max={20} validate onChange={this.changeHandler} />
              </GridCol>
              : null 
            }
          </GridRow>
          { showUpload ?
          <GridRow className='mt-4 mb-3'>
            <GridCol>
              <InputLabel>Email for Reminders *</InputLabel>
              <Input ref='emailInput' value={this.state.emailReminder || ''} type='email' name='emailReminder' placeholder='Email' validate onChange={this.changeHandler} />
            </GridCol>
            <GridCol>
              <InputLabel>Mobile Phone for Text Reminders (optional)</InputLabel>
              <Input ref='phoneInput' value={this.state.phoneReminder || ''} type='tel' name='phoneReminder' placeholder='Phone' onChange={this.changeHandler} />
            </GridCol>
          </GridRow>
          :
          <Fragment>
          <GridRow align='center'>
            { merchantDistributors.length > 1 ? 
            <Fragment>
              <GridCol size={4}>
                <Checkbox text='All Locations' className={checkOnePlease ? 'text-red' : null} name='all' value={checkAll} onChange={this.checkAllHandler}/>
              </GridCol>
              {
                merchantDistributors.length > 1 && checkAll ?
                <Fragment>
                  <GridCol size={4}>
                    { dropdown.value !== 'auto' ?
                      <Button onClick={this.showEnterCredentials.bind(this, 'all', merchantDistributors[0], dcn)} variant='primary' size='smd w-100' text='Connect' />
                      : null
                    }
                  </GridCol>
                  { this.getCorrectIconAll() }
                </Fragment>
                : null
              }
            </Fragment>
            :  <GridCol /> 
          }
            
          </GridRow>
          {merchantDistributors.map((merDist, i) => (
            <GridRow key={i} align='center' className='mb-2'>
              <GridCol size={4}>
                <Checkbox text={merDist.merchant.nickname} className={checkOnePlease ? 'text-red' : ''} name={i} value={check[i]} onChange={this.checkHandler} />
              </GridCol>
              {
                check[i] ?
                <Fragment>
                  <GridCol className='pb-1' size={checkAll ? 4 : undefined}>
                    <InputLabel>Distributor Account # (DCN) *</InputLabel>
                    <Input value={dcn[i]} name={i} ref={'dcn-'+i} placeholder='DCN' min={2} max={20} validate onChange={this.dcnChangeHandler} />
                    {
                      (check[i] && (dcn[i] === '' && isClickButton)) ? 
                        <span className='text-red' style={{fontSize:'11px'}}>Enter DCN to activate distributor </span>
                      :  
                        <span > &nbsp; </span>
                    }
                  </GridCol>
                  {
                    !checkAll ? 
                      <Fragment>
                        <GridCol>
                          { dropdown.value !== 'auto' ?
                            <Button variant='primary' onClick={this.showEnterCredentials.bind(this, i, merDist, [dcn[i]])} size='smd w-100' text='Connect' />
                            : null
                          }
                        </GridCol>
                        { dropdown.value !== 'auto' &&  this.getCorrectIcon(i) }
                      </Fragment>
                    : null
                  }
                </Fragment>
                : null
              }
            </GridRow>
          ))}
          </Fragment>
          }
          { showUpload ?
          <Fragment>
          <GridRow className='mt-4 mb-1'>
            <GridCol size={12} className='text-size-14'>
              <input ref='hiddenFileInput' className='d-none' onChange={() => this.selectFile('excel-upload')} type="file" multiple="multiple" id='excel-upload' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xsl, .xlsx, application/vnd.oasis.opendocument.spreadsheet, application/pdf" />
              <input ref='hiddenFileImageInput' className='d-none' onChange={() => this.selectFile('img-upload')} type="file" multiple="multiple" id='img-upload' accept="image/jpeg,image/x-png" />              {
                fileArray.length === 0 ?
                <div className="d-flex">
                    <DashedBox onClick={this.uploadExcel} textClassName='text-size-12' className={'m-0 clickable w-50'} height='90px'>
                      <IconUpload color={config.colors.rbDarkerGrey} /> 
                      &nbsp; {'Upload pdf, excel or csv file'}</DashedBox>

                    <DashedBox onClick={this.uploadImage} textClassName='text-size-12' className={'m-0 clickable w-50'} height='90px'>
                      <IconUpload color={config.colors.rbDarkerGrey} /> 
                      &nbsp; {imgDashedText}</DashedBox>
                </div>
                :
                fileArray
              }
            </GridCol>
          </GridRow>
          {
            fileArray.length > 0 ?
            <GridRow className='mt-4'>
              <GridCol size={3}>
                <InputLabel>File Start Date</InputLabel>
                <DatePicker name='startDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={startDate} dateFormat="MM/dd/yyyy" maxDate={new Date()} onChange={this.changeDate.bind(this, 'startDate')} />
              </GridCol>
              <GridCol size={3}>
                <InputLabel>File End Date</InputLabel>
                <DatePicker name='endDate' className='rb-input w-100' locale='en' popperPlacement="bottom-start" selected={endDate} dateFormat="MM/dd/yyyy" minDate={startDate} maxDate={new Date()} onChange={this.changeDate.bind(this, 'endDate')} />
              </GridCol>
            </GridRow>
            : null
          }
          </Fragment>
        : null }
        </ModalBody>
        <ModalFooter className='text-left justify-content-start' center={false}>
          <div className="w-100">
            <div className="row pb-2">
              {!isFromEmailNotification &&
                <GridCol size={6}>
                  <Button variant='white' onClick={this.cancel} size='md' text={'Cancel'} />
                </GridCol>}
              <GridCol size={isFromEmailNotification ? 12 : 6} className={isFromEmailNotification ? 'text-center' : 'text-right'}>
                <Button disabled={isBackup ? false : (dropdown.value === 'online' ? false : this.cantClick())} variant='primary' onClick={this.activate} size='md' text={isEdit ? 'Save' : this.isSaveOrActivate()} />
                <Button onClick={this.openCamera} variant='primary' size='lg-fs-md' className='d-none takePictureButton' text='Take Picture' />
              </GridCol>
            </div>
            {addNewFile}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  return {
    locations: state.dashboard.merchants,
    companyId: state.company.id,
    merchantGroup: state.location.merchantGroup,
    notifications: state.notification.notificationList
  };
}

export default connect(mapStateToProps)(ActivateDistributorModal);