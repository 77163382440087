import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import IconDelete from '../../MasterComponents/Icons/IconDelete';
import IconEdit from '../../MasterComponents/Icons/IconEdit';
import config from '../../config/config';
import Button from '../../MasterComponents/Button/Button';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import InviteUserModal from './Modals/InviteUserModal';
import DeleteUserModal from './Modals/DeleteUserModal';
import ConfirmationUserModal from './Modals/ConfirmationUserModal';
import { getIconInformationColor } from '../../config/Company/CompanyHelper';
import IconInformation from '../../MasterComponents/Icons/IconInformation';
import $ from 'jquery';
class DashboardSettingsUsers extends Component {
    constructor(props) {
      super(props);    
      this.state = {
        users: [],
        hasPaymentMethod: false
      }
    }

    componentDidMount = () => {
        const {users} = this.props
        $('[data-toggle="toolTipPaymentRecipient"]').tooltip();
        const hasPayment = users.some(u => Boolean(u.isPaymentRecipient));
        this.setState({
            hasPaymentMethod: hasPayment
        });
    }

    handleInviteUser = () => {        
        const { dispatch, refresh} = this.props;
        const { hasPaymentMethod } = this.state;
        const methodObj = null;
        dispatch(showModal(InviteUserModal, 'inviteUserModal', {header:'Invite User', type: 'add',refresh: refresh, hasPaymentMethod, methodObj}));
    }

    handleEditUser = (user) => {
        const { dispatch, refresh} = this.props;
        const { hasPaymentMethod } = this.state;
        let methodObj = null;
        if(Boolean(user.paymentMethod)) {
            methodObj = {
                label: user.paymentMethod === 'ACH' ? 'ACH (No cost)': 'Check by mail (fee may apply)', 
                value: user.paymentMethod 
            }
        }
        
        dispatch(showModal(InviteUserModal, 'inviteUserModal', {header:'Edit User', type: 'edit',refresh: refresh, user: user, hasPaymentMethod, methodObj}));
    }
  
    handleDeleteUser = (user) => {        
        const { dispatch, refresh} = this.props;
        const currentUser = localStorage.getItem('email');
        if (currentUser === user.email){
            dispatch(showModal(ConfirmationUserModal, 'confirmationUserModal', {header:'Delete User', message: 'You can\'t delete your own user', user: user, refresh: refresh, icon: false}));
        }else{
            dispatch(showModal(DeleteUserModal, 'deleteUserModal', {header:'Delete User', message: 'Do you want to delete this account?', user: user, refresh: refresh}));
        }        
    }
    
    getUserLocations = (userLocations) => {
      const { locations}  = this.props;
      let countLocations = 0;
      locations.forEach(loc => {
        userLocations.forEach(userLocation => {
          if (loc.id === userLocation) countLocations++;
        })
      });
      return countLocations;
    }

    render() {
        const { locations, companyId } = this.props;
        const iconInformationColor = getIconInformationColor(companyId);
        const hasPaymentInformation = companyId === 1 ? true : false;

        const usersMap = this.props.users.map((u, i) => {
            if(isMobile){
                return(
                    <Fragment key={i}>
                        <GridRow className='border-bottom mt-3'>
                            <GridCol className='col-9 ml-3 mr-0'>
                                <GridRow className='pt-2'><span>{u.contactName}</span></GridRow>
                                <GridRow className='pt-2'><span>{u.email}</span></GridRow>
                                <GridRow className='pt-2 pb-3'><span>{u.title}</span></GridRow>
                            </GridCol>
                            <GridCol className='col-2 mx-0 px-0 d-flex justify-content-end'>
                                <div className='pr-4' onClick={this.handleEditUser.bind(this, u)}>
                                    <IconEdit color={config.colors.rbGrey}/>
                                </div>
                                <div className='' onClick={this.handleDeleteUser.bind(this, u)}>
                                    <IconDelete color={config.colors.rbGrey}/>
                                </div>
                            </GridCol>                    
                        </GridRow>
                    </Fragment>
                )
            }else{
                return(
                    <Fragment key={i}>
                        <GridRow className='line-form'>
                            <GridCol className={`form-group ${hasPaymentInformation ? 'col-1' : 'col-3'}`}>
                                <span>{u.contactName}</span>
                            </GridCol>
                            <GridCol className={`form-group text-truncate ${hasPaymentInformation ? 'col-2' : 'col-4'}`}>
                                <span>{u.email}</span>
                            </GridCol>
                            { hasPaymentInformation && 
                            <GridCol className='col-2 form-group'>
                                <span>{u.phoneNumber ? u.phoneNumber : 'N/A' }</span>
                            </GridCol>
                            }
                            <GridCol className={`form-group ${hasPaymentInformation ? 'col-1' : 'col-2'}`}>
                                <span>{u.title}</span>
                            </GridCol>
                            { hasPaymentInformation && 
                            <>
                                <GridCol className='col-2 form-group'>
                                    <span>{u.isPaymentRecipient === 1 ? 'Yes' : 'No'}</span>
                                </GridCol>
                                <GridCol className='col-1 form-group'>
                                    <span>{this.getUserLocations(u.locations) + '/' + locations.length}</span>
                                </GridCol>
                                <GridCol className='col-1 form-group'>
                                    <span>{u.status === 1 ? 'Yes' : 'No'}</span>
                                </GridCol>
                                <GridCol className='col-1 form-group'>
                                    <span>{(u.paymentMethod === '' || u.paymentMethod === null) ?  'N/A' : u.paymentMethod }</span>
                                </GridCol>
                            </>
                            }
                            <GridCol className='col-1 form-group text-right d-flex justify-content-end'>                        
                                <div className='clickable pr-3' onClick={this.handleEditUser.bind(this, u)}>
                                    <IconEdit color={config.colors.rbGrey}/>
                                </div>
                                <div className='clickable' onClick={this.handleDeleteUser.bind(this, u)}>
                                    <IconDelete color={config.colors.rbGrey}/>
                                </div>
                            </GridCol>                    
                        </GridRow>
                    </Fragment>
                )
            }
            
        });

        return (
            <Fragment>
                {isMobile ? null :
                    <GridRow className='line-form mt-3'>
                        <GridCol className={`form-group ${hasPaymentInformation ? 'col-1' : 'col-3'}`}>
                            <InputLabel text='Name'/>
                        </GridCol>
                        <GridCol className={`form-group text-truncate ${hasPaymentInformation ? 'col-2' : 'col-4'}`}>
                            <InputLabel text='Email'/>
                        </GridCol>
                        { hasPaymentInformation &&
                        <GridCol className='col-2 form-group'>
                            <InputLabel text='Phone Number'/>
                        </GridCol>
                        }
                        <GridCol className={`form-group ${hasPaymentInformation ? 'col-1' : 'col-2'}`}>
                            <InputLabel text='Role'/>
                        </GridCol>
                        { hasPaymentInformation &&
                        <> 
                            <GridCol className='col-2 form-group'>
                                <GridRow>
                                    <span className='clickable d-flex align-items-start' data-toggle='toolTipPaymentRecipient' title={`A person who has the right to claim rebates`}> &nbsp;
                                        <IconInformation color={iconInformationColor} size={13} />
                                    </span>
                                    <InputLabel text='Payment Recipient' className='px-1' />
                                </GridRow>
                            </GridCol>
                            <GridCol className='col-1 form-group'>
                                <InputLabel text='Locations'/>
                            </GridCol>
                            <GridCol className='col-1 form-group'>
                                <InputLabel text='Activated'/>
                            </GridCol>
                            <GridCol className='col-1 form-group'>
                                <InputLabel text='Payment Method'/>
                            </GridCol>
                        </>
                        } 
                    </GridRow>
                }
                {usersMap}
                <GridRow className='line-form mt-3'>
                    <GridCol className={(isMobile ?'':'col-4') + ' form-group'}>
                        <Button type='button' variant='primary' size='' text='Invite User'  onClick={this.handleInviteUser}/>
                    </GridCol>
                </GridRow>
            </Fragment>
          );
    }
}

const mapStateToProps = state => {
    return {
      section: state.dashboard.section,
      users: state.dashboard.users,
      locations: state.dashboard.merchants
    };
  }
  
export default connect(mapStateToProps)(DashboardSettingsUsers);
