import React, { useEffect } from 'react'
import ReactGA from 'react-ga';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import DCNCredentialsApi from './DCNCredentialsApi';
import ActivateDistributorModal from '../Dashboard/Modals/ActivateDistributorModal';
import { connect, useDispatch } from 'react-redux';
import setToken from '../../config/tokenApi';
import LocationApi from '../Location/LocationApi';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { distributorSetAll, distributorSetFilter, manufacturerAdSetData, merchantSetData, merchantSetFilter, merchantSetGroup } from '../Location/Reducer/LocationAction';
import { faqSetAll, newSetAll } from '../HomeMain/Reducer/HomeMainAction';
import PrimeProgramsApi from '../PrimePrograms/PrimeProgramsApi';
import HomeApi from '../HomeMain/HomeApi';

const DCNCredentialsForm = (props) => {
    const dispatch = useDispatch();
    const search = props.location.search;
    const token = new URLSearchParams(search).get('token');
    localStorage.setItem('authKey', token);
    setToken(token);

    // From DashboardContent.js
    const init = (cb, params) => {
        const { companyId, history, merchantGroupQuery } = props;
        dispatch(spinner(true));
        LocationApi.getMerchants().then((res) => {
            const merchants = res.data.data;
            dispatch(merchantSetData(merchants));
        });
        
        //Get Distributors Filter
        LocationApi.getDistributorAllFilter().then(({ data }) => {
            dispatch(distributorSetFilter(data.data));
            dispatch(distributorSetAll(data.data.distributors));
            dispatch(merchantSetFilter(data.data.merchants));
            // Get Merchant Groups
            const merDistParams = {
                ...params,
                page: merchantGroupQuery.page
            }
            LocationApi.getMerchantDistributorList(merDistParams).then((res) => {
                const data = res.data;
                dispatch(spinner(false));
                const merchantGroup = data.data.map(m => ({
                ...m,
                merchantDistributors: m.distributors.map(d => ({
                        ...d,
                        merchantId: m.id,
                        dcn: (d.dcn && (d.dcn.toString().trim())) || ''
                    })),
                }));
                dispatch(merchantSetGroup(merchantGroup, res.headers['x-pagination-current-page'], res.headers['x-pagination-page-count']));
                if (cb) cb(merchantGroup);
            });
        });
        //Get Manufacturer Ads
        PrimeProgramsApi.getManufacturerAd(companyId).then(({ data }) => {
            dispatch(manufacturerAdSetData(data.data));
        });
        //Get FAQ by company
        HomeApi.getFaqCompany(companyId).then(({ data }) => {
            dispatch(faqSetAll(data.data));
        });
        //Get FAQ Resource
        HomeApi.getFaqResourceCompany(companyId).then(({ data }) => {
            dispatch(newSetAll(data.data));
        });
        // Redirects to home after closing modals
        let checkModals = setInterval(() => {
            if (document.querySelectorAll('.modal').length === 0) {
                history.push('/');
                clearInterval(checkModals);
            }
        }, 1000);
    }

    const showActivateDistributor = (merDist, isEdit) => {
        ReactGA.event({
            category: isEdit ? 'Edit Distributor' : 'Click Button',
            action: 'Click Activate Distributor'
        });
        dispatch(showModal(ActivateDistributorModal, 'activateDistributorModal', { merchantDistributor: merDist, refresh: init, isEdit: isEdit }));
    };

    useEffect(() => {
        const { notificationId } = props.match.params;
        DCNCredentialsApi.getNotification(notificationId).then((res) => {
            const { merchantDistributor, type } = res.data.data;
            const isEdit = type === 'failedPassword' ? true : false;

            showActivateDistributor(merchantDistributor, isEdit);

            LocationApi.getCurrentUser().then(({ data }) => {
                const { id, contactName, email, username, phoneNumber, title, isFoodbamActive, status } = data.data;
                localStorage.setItem('userid', id);
                contactName ?
                    localStorage.setItem('contactName', contactName) :
                    localStorage.removeItem(contactName);
                username ?
                    localStorage.setItem('username', username) :
                    localStorage.removeItem(username);
                email ?
                    localStorage.setItem('email', email) :
                    localStorage.removeItem(email);
                phoneNumber ?
                    localStorage.setItem('phoneNumber', phoneNumber) :
                    localStorage.removeItem(phoneNumber);
                title ?
                    localStorage.setItem('title', title) :
                    localStorage.removeItem(title);
                localStorage.setItem('isLoggedIn', true);
                isFoodbamActive ?
                    localStorage.setItem('isFoodbamActive', isFoodbamActive) :
                    localStorage.removeItem(isFoodbamActive);
                status ?
                    localStorage.setItem('status', status) :
                    localStorage.removeItem(status);
                localStorage.setItem('showInsideTrackModal', false);
            }).catch(err => {
                console.error(err);
            });
        }).catch(error => {
            console.log(error);
        });
    });

    return (
        <div></div>
    )
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        merchantGroupQuery: state.location.merchantGroupQuery
    };
}

export default connect(mapStateToProps)(DCNCredentialsForm);