import React, { Component } from 'react';
import { connect } from 'react-redux';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { isMobile } from 'react-device-detect';
import { Route, Redirect } from 'react-router-dom';
import GridCol from '../../MasterComponents/Grid/GridCol';
import DashboardFooter from './DashboardFooter';
import DashboardAddNewDistributor from './DashboardAddNewDistributor';
import DashboardAddNewLocation from './DashboardAddNewLocation';
import { merchantSetGroup, merchantSetData, distributorSetFilter, distributorSetAll, saveUserCurrent, manufacturerAdSetData, merchantLoadGroup, merchantSetFilter } from '../Location/Reducer/LocationAction';
import { insightsSetAll, insightsSetOpportunity } from '../HomeMain/Reducer/HomeMainAction';
import { saveActiveMerchant, setDisplayBrowserExtension } from './DashboardActions';
import { notificationSetData, notificationSetCounter } from '../Notification/Reducer/NotificationAction';
import DashboardSettings from './DashboardSettings';
import DashboardHeaderImage from './DashboardHeaderImagejs';
import DashboardEditDistributor from './DashboardEditDistributor';
import PrimePrograms from '../PrimePrograms/PrimePrograms';
import FoodBamHome from '../FoodBam/FoodBamHome';
import FoodBamSignUp from '../FoodBam/FoodBamSignUp';
import FoodBamFrame from '../FoodBam/FoodBamFrame';
import Insights from '../Insights/Insights';
import ClaimCash from '../ClaimCash/ClaimCash';
import ClaimCashDetail from '../ClaimCash/Details/ClaimCashDetail';
import Blog from '../Blog/Blog';
import Faq from '../Faq/Faq';
import Location from '../Location/Location';
import LocationApi from '../Location/LocationApi';
import NotificationApi from '../Notification/NotificationApi';
import FoodCost from '../FoodCost/FoodCost';
import Home from '../HomeMain/Home';
import Joi from 'joi';
import Orderly from '../Orderly/Orderly';
import ItemIntel from '../ItemIntel/ItemIntel';
import ItemIntelDetails from '../ItemIntel/Details/ItemIntelDetails';
import ContractManagement from '../ContractManagement/ContractManagement';
import InsideTrack from '../InsideTrack/InsideTrack';
import InvoiceScanning from '../InvoiceScanning/InvoiceScanning';
import InvoiceSanningApi from '../InvoiceScanning/InvoiceSanningApi';
import { invoiceSetAll } from '../InvoiceScanning/Reducer/InvoiceScanningAction';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import PrimeProgramsApi from '../PrimePrograms/PrimeProgramsApi';

class DashboardContent extends Component {
  componentDidMount = () => {
    const { dispatch, companyId } = this.props;
    //Get Current User/Merchant
    LocationApi.getCurrentUser().then((res) => {
      const data = res.data.data;
      this.init();
      localStorage.setItem('activeMerchant', JSON.stringify(data.activeMerchant));
      localStorage.setItem('status', data.status);
      dispatch(saveActiveMerchant(data.activeMerchant));
      dispatch(saveUserCurrent(data));
      dispatch(setDisplayBrowserExtension(data.displayBrowserExtension));
      //Notifications
      NotificationApi.getNotifications().then(({data}) => {
        const noti = data.data;
        const countNotRead = noti.filter(n => n.isRead === 0).length;
        dispatch(notificationSetData(noti));
        dispatch(notificationSetCounter(countNotRead));
      }).catch(err => {
        console.error(err);
      });
    });
    //Clean Merchants
    dispatch(merchantSetData([]));
    //Clean Insights
    dispatch(insightsSetAll({}));
    dispatch(insightsSetOpportunity([]));
    //Clean Notifications
    dispatch(notificationSetData([]));
    //Get Manufacturer Ads
    PrimeProgramsApi.getManufacturerAd(companyId).then(({data}) => {
      dispatch(manufacturerAdSetData(data.data));
    });
  }

  loadMoreMerchantGroups = (cb, params) => {
    // Get Merchant Groups
    const { dispatch, merchantGroupQuery } = this.props;
    dispatch(spinner(true));
    const merDistParams = {
      ...params,
      page: params ? params.page : merchantGroupQuery.page
    }
    dispatch(merchantLoadGroup(merDistParams.page))
    LocationApi.getMerchantDistributorList(merDistParams).then((res) => {
      const data = res.data;
      dispatch(spinner(false));
      const merchantGroup = data.data.map(m => ({
        ...m,
        merchantDistributors: m.distributors.map(d => ({
          ...d,
          merchantId: m.id,
          nickname: d.distributor,
          dcn: (d.dcn && (d.dcn.toString().trim())) || ''
        })),
      }));
      dispatch(merchantSetGroup(merchantGroup, res.headers['x-pagination-current-page'], res.headers['x-pagination-page-count']));
      if (cb) cb(merchantGroup);
    });
  }

  //New Process
  init = (cb, params) => {
    const { dispatch, companyId, merchantGroupQuery } = this.props;
    
    LocationApi.getMerchants().then((res) => {
      const merchants = res.data.data;
      dispatch(merchantSetData(merchants));
    });
    
    dispatch(spinner(true));
    //Get Distributors Filter
    LocationApi.getDistributorAllFilter().then(({data}) => {
      dispatch(distributorSetFilter(data.data));
      dispatch(distributorSetAll(data.data.distributors));
      dispatch(merchantSetFilter(data.data.merchants));

      // Get Merchant Groups
      const merDistParams = {
        ...params,
        page: merchantGroupQuery.page
      }
      LocationApi.getMerchantDistributorList(merDistParams).then((res) => {
        const data = res.data;
        dispatch(spinner(false));
        const merchantGroup = data.data.map(m => ({
          ...m,
          merchantDistributors: m.distributors.map(d => ({
            ...d,
            merchantId: m.id,
            nickname: d.distributor,
            dcn: (d.dcn && (d.dcn.toString().trim())) || ''
          })),
        }));
        dispatch(merchantSetGroup(merchantGroup, res.headers['x-pagination-current-page'], res.headers['x-pagination-page-count']));
        if (cb) cb(merchantGroup);
      });

      const filterMerchantsIds = (!params || (params && !params.merchantId)) ? data.data.merchants.map(m => m.id) : JSON.parse(params.merchantId);
      //Get Invoices
      const paramsInv = {
        companyId,
        merchantIds: filterMerchantsIds,
        search: '',
        startDate: null,
        endDate: null
      }
      InvoiceSanningApi.getInvoices(paramsInv).then(({data}) => {
        dispatch(invoiceSetAll(data.data.map(d => {
          return {
            ...d,
            isTooltipOpen: false
          }
        })));
      });
    });
  }
 
  getInitTopBar = () => {
    const { displayCashBack, displayInsight, displayClaimCheck, displayBeyondPrime, displayFoodbam, displayItemIntel } = this.props;
    if(displayCashBack && displayInsight && displayBeyondPrime) {
      return '/dashboard/home';
    } else if(displayCashBack) {
      return '/dashboard/rebates/card';
    } else if (displayInsight) {
      return '/dashboard/insights';
    } else if (displayItemIntel) {
      return '/dashboard/itemIntel';
    } else if (displayClaimCheck) {
      return '/dashboard/claim';
    } else if (displayBeyondPrime) {
      return '/dashboard/prime';
    } else if (displayFoodbam) {
      return '/dashboard/foodBAM';
    } else {
      return '/dashboard';
    }
  };
  render() {
    const { props, state, empty } = this;
    const {
      section, location, companyColors, socialLinks, copyright, header, locations, companyId,
      displayCashBack, displayInsight, displayBeyondPrime, displayFoodbam, displayClaimCheck,
      companyLoading, parentMerchant, isOrderly, activeMerchant, userCurrent, displayItemIntel,
      displayContractManagementWhitelabel, displayInsideTrack
    } = props;
    
    const displayContractManagement = displayContractManagementWhitelabel && (activeMerchant && activeMerchant.displayContractManagement === 1);

    let country = 'us';

    if (parentMerchant && parentMerchant.postcode) {
      const COUNTRY_SCHEMA = Joi.string().min(4).pattern(/^[0-9-]+$/).required();
      const isUs = COUNTRY_SCHEMA.validate(parentMerchant.postcode.toString());

      country = isUs.error ? 'ca' : 'us';
    }

    if (companyLoading) {
      return null;
    }
    const hasOrderly = (activeMerchant && activeMerchant.fcmAvailable) && (userCurrent && userCurrent.fcmAccess);

    return (
      <GridCol id='dashboard-content' className='p-0 d-flex flex-column'>
        { section.title === '' && ['/dashboard/prime', '/dashboard/foodBAM', '/dashboard/foodBAM/sign-up', '/dashboard/insights', '/dashboard/claim', '/dashboard/blog', '/dashboard/faq', '/dashboard/foodCost', '/dashboard/home', '/dashboard/itemIntel'].indexOf(location.pathname) === -1 ? <DashboardHeaderImage {...header} /> : null }
        <CacheSwitch>
          <Route exact path='/dashboard/add-new-distributor' render={newProps => <DashboardAddNewDistributor {...props} {...newProps} />} />
          <Route exact path='/dashboard/add-new-location'>
            <DashboardAddNewLocation {...props} country={country} refresh={this.init} />
          </Route>
          <Route exact path='/dashboard/edit-location/:id' render={newProps => <DashboardAddNewLocation {...props} locations={locations} {...newProps} country={country} isEdit refresh={this.init} />} />
          <Route exact path='/dashboard/merchant-distributor/:id' render={newProps => <DashboardEditDistributor {...props} {...newProps} refresh={this.init} {...state} />} />
          {(displayInsight && !displayInsideTrack) && <CacheRoute exact path='/dashboard/insights' render={newProps =><Insights companyColors={companyColors} locationName={(activeMerchant && activeMerchant.nickname) || '...'} empty={empty} refresh={this.init} {...props} {...newProps} {...state} />}  />}
          {displayInsideTrack && <CacheRoute className='blog col-12 p-0 container-fluid h-100' exact path='/dashboard/insights' render={newProps =><InsideTrack companyColors={companyColors} {...props} {...newProps} insidetrackId={userCurrent.insidetrackId} />}  />}
          {displayItemIntel && <Route exact path='/dashboard/itemIntel' render={newProps => <ItemIntel {...props} {...newProps} {...state} refresh={this.init} />} />}
          {displayItemIntel && <Route exact path='/dashboard/itemIntel/:productId' render={newProps => <ItemIntelDetails {...props} {...newProps} {...state} refresh={this.init} />} />}
          {displayFoodbam && <Route exact path='/dashboard/foodBAM' render={props => <FoodBamHome {...props} />} />}
          {displayFoodbam && <Route exact path='/dashboard/foodBAM/sign-up' render={props => <FoodBamSignUp {...props} />} />}
          {displayFoodbam && <Route exact path='/dashboard/foodBAM/app' render={props => <FoodBamFrame {...props} />} />}
          <Route exact path='/dashboard/settings' render={props => <DashboardSettings {...props} />} />
          {displayClaimCheck && <Route exact path ='/dashboard/claim' render={newProps => <ClaimCash {...props} {...newProps} refresh={this.init} {...state} />} />}
          {displayClaimCheck && <Route exact path ='/dashboard/claim/detail' render={newProps => <ClaimCashDetail {...props} {...newProps} refresh={this.init} {...state} />} />}
          {displayBeyondPrime && <Route exact path ='/dashboard/prime' render={newProps => <PrimePrograms {...props} {...newProps} refresh={this.init} {...state} />} />}
          {(!isMobile && companyId === 1) && <Route exact path='/dashboard/blog' render={newProps => <Blog {...props} {...newProps} {...state} />} />}
          {displayCashBack && <Route exact path ='/dashboard/rebates/card' render={newProps => <Location {...props} {...newProps} companyColors={companyColors} refresh={this.init} loadMore={this.loadMoreMerchantGroups} {...state} />} />}
          {(!isMobile && displayCashBack) ? <Route exact path='/dashboard/rebates/list' render={newProps => <Location {...props} {...newProps} companyColors={companyColors} refresh={this.init} loadMore={this.loadMoreMerchantGroups} {...state} /> } /> : null }
          {!isMobile && <Route exact path='/dashboard/faq' render={newProps => <Faq {...props} {...newProps} {...state} />} />}
          {!isMobile && hasOrderly ? (
            <Route exact path='/dashboard/foodCost'>
              <Redirect to='/dashboard/foodCost/orderly' />
            </Route>
            ) :
            null
          }
          {!hasOrderly ? 
            <Route exact path='/dashboard/foodCost' render={newProps => <FoodCost {...props} {...newProps} {...state} />} />
            : 
            null
          }
          {(!isMobile && hasOrderly) && <Route exact path='/dashboard/foodCost/orderly' render={newProps => <Orderly {...props} {...newProps} {...state} />} /> }
          {(displayCashBack && displayInsight && displayBeyondPrime) && <Route exact path ='/dashboard/home' render={newProps => <Home {...props} {...newProps} companyColors={companyColors} refresh={this.init} {...state} />} />}
          {(displayContractManagement && !isMobile) && <Route exact path='/dashboard/contractmanagement' render={props => <ContractManagement {...props} />} />}
          <Route exact path='/dashboard/invoice' render={props => <InvoiceScanning {...props} />} />
          <Route>
            <Redirect to={this.getInitTopBar()} />
          </Route>
        </CacheSwitch>
        {((isMobile && (section.title==='Settings' || section.title==='Item Intel')) || isOrderly)? null : <DashboardFooter {...socialLinks} copyright={copyright} companyId={companyId} />}
      </GridCol>
    );
  }
}

const mapStateToProps = state => {
  return {
    section: state.dashboard.section,
    activeMerchant: state.dashboard.activeMerchant,
    userCurrent: state.location.userCurrent,
    locations: state.location.merchant,
    companyLoading: state.company.loading,
    companyColors: state.company.color,
    companyId: state.company.id,
    socialLinks: state.company.socialLinks,
    copyright: state.company.text.copyright,
    header: {
      text: state.company.text.dashboardHeader,
      logo: state.company.dashboardMedia.logo,
      background: state.company.dashboardMedia.background
    },
    parentMerchant: state.location.merchant.filter(m => m.parent)[0] && state.location.merchant.filter(m => m.parent)[0].parent,
    displayCashBack: state.company.displayCashBack,
    displayInsight: state.company.displayInsight,
    displayItemIntel: state.company.displayItemIntel,
    displayClaimCheck: state.company.displayClaimCheck,
    displayBeyondPrime: state.company.displayBeyondPrime,
    displayFoodbam: state.company.displayFoodbam,
    displayContractManagementWhitelabel: state.company.displayContractManagement,
    merchantGroupQuery: state.location.merchantGroupQuery
  };
}

export default connect(mapStateToProps)(DashboardContent);
