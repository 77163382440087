import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../SignUp.scss';
import { spinner } from '../../../../MasterComponents/Spinner/SpinnerActions';
import SignUpApi from '../../SignUpApi';
import {sorArrayByKey} from '../../../../utils/utils';
import FormExistingUserStep1 from './FormExistingUserStep1';
import moment from 'moment';
import FormExistingUserSuccess from './FormExistingUserSuccess';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { showModal } from '../../../../MasterComponents/Modals/ModalsActions';
import ReactHtmlParser from 'react-html-parser';
import queryString from 'query-string';
import FormExistingPayment from './FormExistingPayment';

class FormExistingUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            platformId: '',
            businessName: '',
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            password: '',
            repeatPassword: '',
            error: '',
            success: false,
            daSalesRep: null,
            daSalesRepFromApi: [],
            referredBy: '',
            referredName: '',
            referredByLock: false,
            referredPartnerFromApi : [],
            mergedDaSalesRepReferred: null,
            mergedDaSalesRepReferredFromApi: [],
            isGpo: false,
            gpoName: '',
            isDistributorManufacturerAgreement: false,
            nameOfCompanies: [],
            isAuthorized: false,
            isAuthorizedInvalid: false,
            isFsrAuthorized: false,
            isFsrAuthorizedInvalid: false,
            signedText: '',
            startDate: new Date(),
            disabledRefferedBy: false,
            payment: {
                useSameContact: false,
                firstName: "",
                lastName: "",
                contactName: "",
                title: "",
                titleObj: null,
                phoneNumber: "",
                email: "",
                emailRepeat: "",
                method: "",
                methodObj: null
              }
        };
    };
    componentDidMount() {
        const { defaultChannelPartner, hideSalesRep, companyId } = this.props;
        if (hideSalesRep || companyId === 19) {
            if (defaultChannelPartner) {
                this.setState({
                    referredBy: {
                        value: defaultChannelPartner.id,
                        isDaSalesRep: true,
                        platformId: defaultChannelPartner.id,
                        label: defaultChannelPartner.description,
                        lock: true
                    }
                })
            }
        }
        this.getReferredPartner(()=> {
            this.getSalesRep();
            this.getInitialRefferedBy();
        });
        this.autoFillExisting();
    };
    getSalesRep = () => {
        const action = this.props.dispatch;
        const search = null;
        let page = 1;
        let result = [];
        const apiCallSaleRep = () => {
            const params = {
                search,
                page
            }
            SignUpApi.getSalesRep({params}).then((res) => {
                const loadMore =  parseInt(res.headers['x-pagination-current-page']) < parseInt(res.headers['x-pagination-page-count']);
                const { data } = res;
                result = [...result, ...data.data];
                const daSalesRepFromApi = result.map(dataSales => ({
                    id: dataSales.id,
                    name: dataSales.firstName + ' ' + dataSales.lastName,
                    isDaSalesRep: true,
                    platformId: null
                }));
                if (loadMore) {
                    page += 1;
                    apiCallSaleRep();
                } else {
                    const salesRepSort = sorArrayByKey(daSalesRepFromApi, 'name');
                    this.setState({
                        daSalesRepFromApi,
                        mergedDaSalesRepReferredFromApi: salesRepSort
                    });
                }
            }).catch(function (error) {
                if (error) {
                    let errorMessage = error.data.data.message;
                    action(showModal(ConfirmationModal, 'confirmationModal', { header:'Message', icon: false, message: errorMessage }));
                }
            });
        }
        apiCallSaleRep();
    };
    getReferredPartner = (cb) => {
        const { companyCode, companyId, dispatch } = this.props;
        const brand = companyCode;
        const search = null;
        let page = 1;
        let result = [];

        let channelUrl = null;
        const { search: searchUrl } = this.props.history.location;
        if (searchUrl) {
            const qp = queryString.parse(searchUrl);
            const { channel_partner } = qp;
            channelUrl = channel_partner || null;
        }
        const apiCall = () => {
            SignUpApi.getReferredPartner(companyId, brand, page, search).then((res) => {
                const loadMore =  parseInt(res.headers['x-pagination-current-page']) < parseInt(res.headers['x-pagination-page-count']);
                const { data } = res;
                result = [...result, ...data.data];
                const referred = result.map(ref => ({
                    id: ref.id,
                    name: ref.name,
                    isDaSalesRep: false,
                    platformId: ref.platformId
                }));
                if (channelUrl) {
                    if (referred.some(r => r.platformId === channelUrl)) {
                        const r = referred.find(r => r.platformId === channelUrl);
                        const value = {
                            label: r.name,
                            value: r.id,
                            platformId: r.platformId,
                            isDaSalesRep: r.salesRepId
                        }
                        this.setState({
                            referredByLock: true,
                            referredBy: value,
                            referredName: value.name
                        });
                    }
                }
                if (loadMore) {
                    page += 1;
                    apiCall();
                } else {
                    const stateSort = sorArrayByKey(referred, 'name') // sorting the values by name
                    this.setState({ referredPartnerFromApi: result,  mergedDaSalesRepReferredFromApi: stateSort});
                    cb();
                }
            }).catch(function (error) {
                if (error) {
                    let errorMessage = error.data.data.message;
                    dispatch(showModal(ConfirmationModal, 'confirmationModal', { header:'Message', icon: false, message: errorMessage }));
                }
            });
        };

        apiCall();
    };
    getInitialRefferedBy = () => {
        const { initReferredBy } = this.props;
        const { referredPartnerFromApi } = this.state;
        if (initReferredBy) {
            const found = referredPartnerFromApi.filter((ref) => {
            if (initReferredBy.platformId) {
                return ref.platformId === initReferredBy.platformId
            };
            return ref.id === initReferredBy.value;
            })[0];

            const item = found ? {
            label: found.name,
            value: found.id,
            platformId: found.platformId,
            isDaSalesRep: found.salesRepId
            } : {
            ...initReferredBy
            }

            this.selectReferredBy(item);
            this.disabledRefferedBy();
        }
    };
    disabledRefferedBy = () => {
        this.setState({
            disabledRefferedBy: true
        });
    };
    selectDASalesRep = (value) => {
        this.setState({
            daSalesRep: value
        });
    };
    selectReferredBy = (value) => {
        this.setState({
            referredBy: value,
            referredName : value.label
        });
    };
    nameOfCompaniesHandler = (value) => {
        this.setState({
            nameOfCompanies: value.map(item => {
                if (item.isCustom) {
                    return {
                        ...item,
                        label: item.value
                    };
                }
                return item;
            })
        });
    };
    setError = (value) => {
        this.setState({
            error: value
        });
    };
    authorizationLOTChecked = (value) => {
        const prevValue = this.state.isLOTAuthorized;
        const newValue = value === true ? true : (value === false ? false : !prevValue);
        this.setState({
            isLOTAuthorized: newValue,
            isLOTAuthorizedInvalid: false,
            isGpo: newValue ? 1 : 0,
            gpoName: newValue ? this.state.gpoName : ''
        });
      };
    changeHandler = (value, name) => {
        if (name === 'email') {
            sessionStorage.setItem('signUpEmail', value);
        }
        this.setError('');
        this.setState({
            [name]: value
        });
    };
    selectTitle = (value) => {
        this.setState({
            title: value
        });
    };
    exit = () => {
        this.props.history.push('/register');
    };

    autoFillExisting = () => {
        const { search } = this.props.history.location;
        if (search) {
            const qp = queryString.parse(search);
            const { platform_id,  location_name} = qp;
            this.setState({
                platformId: platform_id,
                businessName: location_name
            });
        }
    };

    //--------------- STEP PAYMENT-----------------------------------//
    sameContactInformation = (isSameContact) => {
        const { firstName, lastName, businessName, title, phoneNumber, email, payment } = this.state;
        this.setState({
        payment : {
            ...payment,
            firstName: isSameContact ? firstName : '',
            lastName: isSameContact ? lastName : '',
            contactName: isSameContact ? businessName : '',
            title: isSameContact ? title : '',
            titleObj: isSameContact ? { label: title, value: title } : null,
            phoneNumber: isSameContact ? phoneNumber : '',
            email: isSameContact ? email : '',
            emailRepeat: isSameContact ? email : '',
            method: payment.method,
            useSameContact: isSameContact
        }
        });
    };

    onChangeInputPayment = (value, name) => {
        const { payment } = this.state;
        this.setState({
        payment: {
            ...payment,
            [name]: value
        }
        });
    };

    onChangeTitlePayment = (obj) => {
        this.setState({
        payment: {
            ...this.state.payment,
            titleObj: obj,
            title: obj.label
        }
        });
    }

    onChangePaymentMethod = (obj) => {
        this.setState({
            payment: {
                ...this.state.payment,
                methodObj: obj,
                method: obj.value
            }
        });
    }
    //--------------- STEP PAYMENT -----------------------------------//

    done = () => {
        const { state, props, setError, goToStep, signUp } = this;
        const { dispatch, companyId } = props;
        const { platformId } = state;
        dispatch(spinner(true));
        SignUpApi.verifyAccountLOP(platformId).then(({data}) => {
            dispatch(spinner(false));
            if (companyId === 1) {
                goToStep(2);
            } else {
                signUp();
            }
        }).catch((err) => {
            console.error(err);
            dispatch(spinner(false));
            setError('Something went wrong');
            dispatch(showModal(ConfirmationModal, 'confirmationModal', { header:'Message', icon: false, message: 'Something went wrong' }));
        });
    };
    signUp = () => {
        const { state, props, setError, goToStep } = this;
        const { dispatch, companyId, companyCode, companyName, isFsrAuthorized, externalBrand, externalBrandCode, segment, subsegment, campaign } = props;
        const { firstName, lastName, title, email, password, businessName,
            repeatPassword, platformId,
            isGpo, nameOfCompanies, gpoName, isDistributorManufacturerAgreement,
            signedText, startDate, daSalesRep,  referredBy, payment} = state;
            //External Brand Code
            const brandCodeValue = externalBrand ? externalBrandCode : companyCode;
        const lastStep = companyId === 1 ? 3 : 2;
        dispatch(spinner(true));
        const params = {
            payment,
            siteUrl: `https://${window.location.hostname}/`,
            companyId: companyId,
            contactName: firstName + ':' + lastName,
            title: title,
            email: email,
            password: password,
            passwordRepeat: repeatPassword,
            parentPlatformId: platformId,
            brandCode: brandCodeValue,
            brand: companyName,
            businessName: businessName,
            daSalesRep: daSalesRep ? daSalesRep.label : null,
            referredBy: referredBy ? referredBy.platformId : null,
            lop: {
                isFsr: isFsrAuthorized ? 1 : 0,
                isGpo: isGpo ? 1 : 0,
                gpoName: isGpo ? (gpoName ? gpoName : '') : '',
                isDistributorManufacturerAgreement: isDistributorManufacturerAgreement ? 1 : 0,
                nameOfCompanies: isDistributorManufacturerAgreement ? (nameOfCompanies.map(item => {
                    return item.nickname
                }).toString()) : '',
                manufacturers: isDistributorManufacturerAgreement ? (nameOfCompanies.map(item => {
                    return item.id
                  })) : [],
                signedText,
                legalName: businessName,
                terminationDate: moment(startDate).format('YYYY-MM-DD')
            },
            segment: segment,
            subsegment: subsegment,
            primaryCampaign: campaign
        };
        SignUpApi.signupSF(params).then(({data}) => {
            dispatch(spinner(false));
            goToStep(lastStep);
        }).catch(({data}) => {
            dispatch(spinner(false));
            if (data) {
                console.error(data);
                let errorMessage = data.data.message;
                setError(data.data.message);
                dispatch(showModal(ConfirmationModal, 'confirmationModal', { header:'Message', icon: false, message: ReactHtmlParser(errorMessage)}));
            }
        });
    };
    goToStep = (newStep) => {
        const { history, pathname, step } = this.props;
        if (newStep > step) {
            history.push(`${pathname}/${newStep}`);
        } else {
            history.replace(`${pathname}/${newStep}`);
        }
    };
    render() {
        const { state, changeHandler, selectTitle, exit, done, setError, authorizationLOTChecked,
               selectDASalesRep, selectReferredBy, goToStep, nameOfCompaniesHandler } = this;
        const { step, companyId, companyName, companyCode, dispatch, companyText, hideSalesRep } = this.props;
        const { success, ...allState } = state;
        const infoProps = {
            ...allState,
            hideSalesRep,
            changeHandler,
            authorizationLOTChecked,
            selectTitle,
            exit,
            done,
            setError,
            selectReferredBy,
            selectDASalesRep,
            goToStep,
            step,
            companyId,
            brandSelected: {
                name: companyName,
                abbreviation: companyCode.toUpperCase()
            },
            dispatch,
            nameOfCompaniesHandler,
            companyText
        }
        const propsPayment = {
            onChangeInputPayment: this.onChangeInputPayment,
            onChangeTitlePayment: this.onChangeTitlePayment,
            onChangePaymentMethod: this.onChangePaymentMethod,
            onBackClick: this.onBackClick,
            onNextClick: this.onNextClick,
            sameContactInformation: this.sameContactInformation,
            onSignUpDone: this.signUp
        }
        return (
            <div className='signup-form mt-3'>
                { step === 1 && <FormExistingUserStep1 {...infoProps} /> }
                { (step === 2 && companyId === 1) && <FormExistingPayment {...infoProps} {...propsPayment} /> }
                { (step === 2 && companyId !== 1) && <FormExistingUserSuccess {...infoProps} /> }
                { (step === 3 && companyId === 1) && <FormExistingUserSuccess {...infoProps} /> }
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        companyId: state.company.id,
        companyName: state.company.nickname,
        companyCode: state.company.code,
        companyText: state.company.text,
        externalBrand: state.company.externalBrand,
        externalBrandCode: state.company.externalBrandCode,
        segment: state.company.segment,
        defaultChannelPartner: state.company.defaultChannelPartner,
        subsegment: state.company.subsegment
    };
}

export default connect(mapStateToProps)(FormExistingUser);
