import React from 'react';
import './Step.scss';
import IconCheck from '../Icons/IconCheck';
import PropTypes from 'prop-types';

const Step = props => (        
    <span className={(props.className ? ` ${props.className}` : '') + ' step step-' + props.status} style={props.style}>{ props.status.substring(0,4) !== 'done' ? props.text : <IconCheck/>}</span>
);

Step.propTypes = {    
    status: PropTypes.string,
    className: PropTypes.string,
};
  
Step.defaultProps = {
    status: 'inactive' // inactive - active - done
};

export default Step;