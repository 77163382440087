import React from 'react';
import { Row, Col } from 'reactstrap';

const DashboardSettingsBanner = () => {
  return (
    <Row className='p-2 bg-secondary d-flex align-items-center' style={{ height: '40px'}}>
      <Col  className='text-center text-size-16 line-height-20 text-white'>
        You can now set a user as a Payment Recipient. Click the Edit icon or Invite new user to setup.
      </Col>
    </Row>
  )
};
export default DashboardSettingsBanner;