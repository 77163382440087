import { useDispatch, useSelector } from 'react-redux';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import { merchantSetGroup, merchantSetData, distributorSetFilter, distributorSetAll, merchantSetFilter } from '../../views/Location/Reducer/LocationAction';
import LocationApi from '../../views/Location/LocationApi';

const useRefresh = () => {
  const merchantGroupQuery = useSelector(state => state.location.merchantGroupQuery);
  const dispatch = useDispatch();
  const refresh = (cb, params) => {
    dispatch(spinner(true));
    LocationApi.getMerchants().then((res) => {
      const merchants = res.data.data;
      dispatch(merchantSetData(merchants));
    });
    
    //Get Distributors Filter
    LocationApi.getDistributorAllFilter().then(({data}) => {
      dispatch(distributorSetFilter(data.data));
      dispatch(distributorSetAll(data.data.distributors));
      dispatch(merchantSetFilter(data.data.merchants));

      // Get Merchant Groups
      const merDistParams = {
        ...params,
        page: merchantGroupQuery.page
      }
      LocationApi.getMerchantDistributorList(merDistParams).then((res) => {
        const data = res.data;
        dispatch(spinner(false));
        const merchantGroup = data.data.map(m => ({
          ...m,
          merchantDistributors: m.distributors.map(d => ({
            ...d,
            merchantId: m.id,
            dcn: (d.dcn && (d.dcn.toString().trim())) || ''
          })),
        }));
        dispatch(merchantSetGroup(merchantGroup, res.headers['x-pagination-current-page'], res.headers['x-pagination-page-count']));
        if (cb) cb(merchantGroup);
      });
    });
  }
  return refresh;
}

export default useRefresh;
