import React, { Component } from 'react'
import { Row, Col } from 'reactstrap';
import { getIconInformationColor } from '../../../../config/Company/CompanyHelper';
import config from '../../../../config/config';
import Button from '../../../../MasterComponents/Button/Button';
import Checkbox from '../../../../MasterComponents/Checkbox/Checkbox';
import DropDownListSelect from '../../../../MasterComponents/DropDownList/DropDownListSelect';
import IconInformation from '../../../../MasterComponents/Icons/IconInformation';
import Input from '../../../../MasterComponents/Input/Input';
import InputLabel from '../../../../MasterComponents/InputLabel/InputLabel';
import $ from 'jquery';

 class FormDefaultPayment extends Component {
  componentDidMount = () => {
    $('[data-toggle="toolTipUseContact"]').tooltip();
    $('[data-toggle="toolTipPaymentMethod"]').tooltip();
  }

  isValid = () => {
    const { firstName, lastName, title, phoneNumber, email, confirmEmail, method} = this.refs;
    const fn = firstName.isValid();
    const ln = lastName.isValid();
    const t = title.isValid();
    const ph = phoneNumber.isValid();
    const e = email.isValid();
    const ce = confirmEmail.isValid();
    const m = method.isValid();
    if(fn && ln && t && ph && e && ce && m) {
      return true;
    }
    return false;
  }

  validateEmail = () => {
    const { email } = this.refs;
    if(/info/.test(email.props.value)){
      email.setInvalid();
    }
  };

  onNext = () => {
    const result = this.isValid();
    if(!result) return;
    this.props.onNextClick();
  }

  render() {
    const { onBackClick, sameContactInformation, payment, onChangeInputPayment, onChangeTitlePayment, onChangePaymentMethod, companyId } = this.props;
    const { firstName, lastName, phoneNumber, email, emailRepeat, methodObj, titleObj, useSameContact } = payment;
    const iconInformationColor = getIconInformationColor(companyId);
    let listOptionTitle = config.signUpStepOneTitleOptions.map((t, i) => {
      return {
        value: i,
        label: t
      }
    });
    let listMethod = [{value: 'ACH', label: 'ACH (No cost)'}, {value: 'Check by mail', label: 'Check by mail (fee may apply)'}]; 
    return (
      <div>
        <Row className='line-form'>
          <Col className='form-group'>
            <InputLabel text='First Name *'/>
            <Input ref='firstName' type='text' name="firstName" value={firstName || ''} onChange={onChangeInputPayment} validate min={3} max={100} placeholder='First Name' />
          </Col>
          <Col className='form-group'>
            <InputLabel text='Last Name *'/>
            <Input ref='lastName' type='text' name="lastName" value={lastName || ''} onChange={onChangeInputPayment} validate min={3} max={100} placeholder='Last Name' />
          </Col>
          <Col className='form-group'>
            <InputLabel text='Title *'/>
            <DropDownListSelect
              name='title'
              ref='title'
              value={titleObj} onChange={onChangeTitlePayment}
              options={listOptionTitle}
               />
          </Col>
        </Row>
        <Row className='line-form'>
          <Col className='form-group'>
            <InputLabel text='Contact Mobile Number *'/>
            <Input ref='phoneNumber' type='tel' name='phoneNumber' value={phoneNumber || ''} onChange={onChangeInputPayment} validate min={3} max={50} placeholder='Contact Phone Number' />
          </Col>
          <Col className='form-group'>
            <InputLabel text='Email *'/>
            <Input ref='email' type='email' name='email' value={email || ''} onChange={onChangeInputPayment || ''} onBlur={this.validateEmail} validate min={3} max={100} placeholder='Contact Email' />
          </Col>
          <Col className='form-group'>
            <InputLabel text='Confirm Email *'/>
            <Input ref='confirmEmail' type='email' name='emailRepeat' value={emailRepeat || ''} onChange={onChangeInputPayment} validate min={3} max={100} placeholder='Repeat Email' />
          </Col>
        </Row>
        <Row className='line-form'>
          <Col xs={12}>
            Enabling this checkbox will use the same contact information provided on Step 1.
          </Col>
          <Col>
            <Row className='px-3'>
              <Checkbox colorClassName={'be-blue'} name='isUseContact' value={useSameContact} text='Use Contact Infomation from Step 1' onChange={()=>sameContactInformation(!useSameContact)} className={useSameContact ? 'invalid' : ''} /> &nbsp;
              <span className='clickable d-flex align-items-center' data-toggle='toolTipUseContact' title={`Use the same contact information provided in Step 1`}>
                <IconInformation color={iconInformationColor} size={13} />
              </span>
            </Row>
          </Col>
        </Row>
        <Row className='line-form'>
          <Col className='form-group'>
            <Col className='px-0'>
              <InputLabel text='Payment Method' className='text-size-16 line-height-19'/>
            </Col>
            <Col>
              <Row>
                <InputLabel text='Specify how you want to get paid *' className='text-size-14 line-height-16' /> &nbsp;
                <span className='clickable d-flex align-items-center mb-2' data-toggle='toolTipPaymentMethod' title={`Select which method you would like to claim your rebates`}>
                  <IconInformation color={iconInformationColor} size={13} />
                </span>
              </Row>
            </Col>
            <DropDownListSelect
              name='method'
              ref='method'
              value={methodObj} onChange={onChangePaymentMethod}
              options={listMethod}
               />
          </Col>
          <Col />
        </Row>
        <Row className='line-form'>
          <Col className='form-group'>
            <Button type='button' variant='white' size='' text='BACK' onClick={onBackClick}/>
          </Col>
          <Col className='form-group'>
            <Button type='button' variant='primary' size='' text='NEXT' onClick={this.onNext}/>
          </Col>
        </Row>
      </div>
    )
  }
}

export default FormDefaultPayment;

