import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { spinnerImage } from '../../MasterComponents/Spinner/SpinnerActions';
import { Button } from 'reactstrap';
import { showModal, destroyModal } from '../../MasterComponents/Modals/ModalsActions';
import { setEnableInsightsModal } from '../Dashboard/DashboardActions';
import { connect } from 'react-redux';
import moment from 'moment';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import InsightsBodyItems from './InsightsBodyItems';
import InsightsBodyManufacturers from './InsightsBodyManufacturers';
import InsightsBodyManufacturersRebate from './InsightsBodyManufacturersRebate';
import InsightsBodyCategories from './InsightsBodyCategories';
import InsightsBodyDistributors from './InsightsBodyDistributors';
import InsightsApi from './InsightsApi';
import InsightsDateRangeModal from './Modals/InsightsDateRangeModal';
import ManageLocationsModal from '../../views/Dashboard/Modals/ManageLocationsModal';
import VerifyRespondModal from '../Dashboard/Modals/VerifyRespondModal';
import InsightsEmpty from './InsightsEmpty';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import config from '../../config/config';
import ReactGA from 'react-ga';
import  './Insights.scss';
import $ from 'jquery';
import InsightsBodyOverallStats from './InsightsBodyOverallStats';
import InsightsBodyOpportunities from './InsightsBodyOpportunities';
import InsightsBodyShowOpportunities from './InsightsBodyShowOpportunities';
import InsightsBodyOpportunitiesModal from './Modals/InsightsBodyOpportunitiesModal';
import AlertModal from '../../MasterComponents/AlertModal.js/AlertModal';
import LocationMangeModalDelete from '../Location/Modals/LocationMangeModalDelete';
import LocationDropdown from '../Location/LocationDropdown/LocationDropdown';
import LocationApi from '../Location/LocationApi';
import InsightsInsideTrackModal from './Modals/InsightsInsideTrackModal';
import InsightsBodyManufacturersDeviation from './InsightsBodyManufacturersDeviation';
import { getTextLinkColor } from '../../config/Company/CompanyHelper';

class InsightsBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInsideTrackModal: false,
            merchantSelected: [],
            displayOpportunity: false,
            displayDeviations: false,
            dataInsights : [],
            dataFound : true,
            period : {
                isWeekActivated : false,
                isMonthActivated : false,
                isYearActivated : true,
                isCustomActivated : false,
            },
            datePeriod : {
                startDate: '',
                endDate: ''
            },
            tabData : {
                isSelectedItems : true,
                isSelectedCategories : false,
                isSelectedDistributors : false,
                isSelectedManufacturer : false
            },
            dataOpportunities: {
                loading: false,
                dataSummary: [],
                show: true,
                dataItemsAll: []
            },
            dataItems : {
                dataItemsTop : [],
                dataItemsAll : [],
                allItems : false,
                initNumberItems : 5,
                numberItems : 5
            },
            dataCategories : {
                dataCategoriesTop : [],
                dataCategoriesAll : [],
                allCategories : false,
                initNumberCategories : 5,
                numberCategories : 5
            },
            dataDistributors :  {
                dataDistributorsTop : [],
                dataDistributorsAll : [],
                allDistributors : false,
                initNumberDistributors : 5,
                numberDistributors : 5
            },
            dataManufacturers :  {
                dataManufacturersTop : [],
                dataManufacturersAll : [],
                allManufacturers : false,
                initNumberManufacturers : 5,
                numberManufacturers : 5
            },
            dataManufacturersRebate :  {
                dataManufacturersTop : [],
                dataManufacturersAll : [],
                allManufacturers : false,
                initNumberManufacturers : 5,
                numberManufacturers : 5
            },
            dataManufacturersDeviation :  {
                dataManufacturersTop : [],
                dataManufacturersAll : [],
                allManufacturers : false,
                initNumberManufacturers : 5,
                numberManufacturers : 5
            },
            tabManufacturers : {
                isSelectedVolume : true,
                isSelectedRebate : false,
                isSelectedDeviation : false
            },
            purchasedRebateItems: [],
            purchasedNonRebateItems: []
        };
    }

    resetStates = () => {
        this.setState({
            merchantSelected: [],
            dataInsights : [],
            dataOpportunities: {
                loading: false,
                dataSummary: [],
                show: true,
                dataItemsAll: []
            },
            dataItems : {
                dataItemsTop : [],
                dataItemsAll : [],
                allItems : false,
                initNumberItems : 5,
                numberItems : 5
            },
            dataCategories : {
                dataCategoriesTop : [],
                dataCategoriesAll : [],
                allCategories : false,
                initNumberCategories : 5,
                numberCategories : 5
            },
            dataDistributors :  {
                dataDistributorsTop : [],
                dataDistributorsAll : [],
                allDistributors : false,
                initNumberDistributors : 5,
                numberDistributors : 5
            },
            dataManufacturers :  {
                dataManufacturersTop : [],
                dataManufacturersAll : [],
                allManufacturers : false,
                initNumberManufacturers : 5,
                numberManufacturers : 5
            },
            dataManufacturersRebate :  {
                dataManufacturersTop : [],
                dataManufacturersAll : [],
                allManufacturers : false,
                initNumberManufacturers : 5,
                numberManufacturers : 5
            },
            dataManufacturersDeviation :  {
                dataManufacturersTop : [],
                dataManufacturersAll : [],
                allManufacturers : false,
                initNumberManufacturers : 5,
                numberManufacturers : 5
            },
        });
    }

    componentDidMount = () => {
        const { displayInsight} = this.props;
        if(displayInsight) {
            $('[data-toggle="tooltInsightsTotalPurchases"]').tooltip();
            $('[data-toggle="tooltInsightsTotalRebates"]').tooltip();
            this.getInit();
        } else {
            window.history.back();
        }
    }

    componentWillUnmount = () => {
        const {dispatch } = this.props;
        dispatch(destroyModal('idInsightsInsideTrackModal'));
    }

    getInit = () => {
        this.onClickPeriodTime('month');
    }

    acknowledged = () => {
        const { dispatch } = this.props;
        this.onClickPeriodTime('month');
        dispatch(setEnableInsightsModal(false));
    }

    scheduleDemo = () => {
      const { dataInsights } = this.state;
      InsightsApi.saveScheduleDemo(dataInsights.annualizedPurchases).then(({data}) => {
      }).catch(error => {
        console.log(error)
      });
    }

    getSpinnerLoader = (spinner) => {
        if(spinner > 4) return 1
        return spinner + 1;
    }

    getData = (startDate, endDate) => {
        const { dispatch, merchantAll } = this.props;
        const {
            dataOpportunities, dataItems, dataCategories,
            dataDistributors, dataManufacturers, dataManufacturersRebate,
            showInsideTrackModal, dataManufacturersDeviation, merchantSelected
        } = this.state;
        const {spinnerLoader} = this.props;
        const spin = this.getSpinnerLoader(spinnerLoader)
        dispatch(spinnerImage(true, spin));
        //TODO
        const allPlatformIds = merchantAll.length > 0 ? merchantAll.map(m => m.platformId) : [];
        const platformIds = merchantSelected.length > 0 ? merchantSelected.map(m => m.platformId) : allPlatformIds;

        const uniquePlatformIds = platformIds.filter((v) => v !== null).filter((v, i, s) => {
            return s.indexOf(v) === i;
        });

        LocationApi.getCurrentUser().then(({data}) => {
            this.setState({
                displayOpportunity: data.data.displayOpportunity === 1,
                displayDeviations: data.data.displayDeviations === 1
            }, () => {
                InsightsApi.getInsights(startDate, endDate, uniquePlatformIds).then( res => {
                    dispatch(spinnerImage(false, spin));
                    const dataInsights = res.data.data;
                    const displayDeviations = data.data.displayDeviations === 1
                    //Inside Track
                    if (showInsideTrackModal && dataInsights.annualizedPurchases > 1000000) {
                        localStorage.setItem('showInsideTrackModal', false);
                        setTimeout(()=> {
                            dispatch(showModal(InsightsInsideTrackModal, 'idInsightsInsideTrackModal', { scheduleDemo : this.scheduleDemo }));
                        }, 1000);
                    }
                    //Opportunities
                    const dataInsightsOpportunitiesSummary = [{
                        amount: displayDeviations ? dataInsights.purchasesPercentages[1] : dataInsights.totalPurchasePercentage,
                        y: displayDeviations ? dataInsights.purchasesPercentages[1] : dataInsights.totalPurchasePercentage,
                        color : this.getRandomColor(1),
                        description: 'Purchases without rebates and/or Deviations'
                    }, {
                        amount: displayDeviations ? dataInsights.purchasesPercentages[0] : dataInsights.totalRebatePercentage,
                        y: displayDeviations ? dataInsights.purchasesPercentages[0] : dataInsights.totalRebatePercentage,
                        color : this.getRandomColor(0),
                        description: 'Purchases with rebates and/or Deviations'
                    }];

                    //Items
                    const dataInsightsItems = dataInsights.items.sort((a, b) => b.amount - a.amount).map((item, i)=> {
                        return {
                            ...item,
                            y : item.amount,
                            color : this.getRandomColor(i)
                        }
                    });
                    const dataInsightsItemsTop = dataInsightsItems.slice(0, dataItems.initNumberItems);
                    const allItems = dataInsightsItemsTop.length >= dataInsightsItems.length ? true : false;
                    //Categories
                    const dataInsightsCategories = dataInsights.categories.map((item, i)=> {
                        return {
                            ...item,
                            y : item.amount,
                            color : this.getRandomColor(i)
                        }
                    });
                    const dataInsightsCategoriesTop = dataInsightsCategories.slice(0, dataCategories.initNumberCategories);
                    const allCategories = dataInsightsCategoriesTop.length >= dataInsightsCategories.length ? true : false;
                    //Distributors
                    const dataInsightsDistributors = dataInsights.distributors.sort((a, b) => b.amount - a.amount).map((item, i)=> {
                        return {
                            ...item,
                            y : item.amount,
                            color : this.getRandomColor(i)
                        }
                    });
                    const dataInsightsDistributorsTop = dataInsightsDistributors.slice(0, dataDistributors.initNumberDistributors);
                    const allDistributors = dataInsightsDistributorsTop.length >= dataInsightsDistributors.length ? true : false;
                    //Manufacturers - Volume
                    const dataInsightsManufacturers = dataInsights.manufacturersVolume.sort((a, b) => b.amount - a.amount).map((item, i)=> {
                        return {
                            ...item,
                            y : item.amount,
                            color : this.getRandomColor(i)
                        }
                    });
                    const dataInsightsManufacturersTop = dataInsightsManufacturers.slice(0, dataManufacturers.initNumberManufacturers);
                    const allManufacturers= dataInsightsManufacturersTop.length >= dataInsightsManufacturers.length ? true : false;
                    //Manufacturers - Rebate
                    const dataInsightsManufacturersRebate = dataInsights.manufacturersRebate.map((item, i)=> {
                        return {
                            ...item,
                            y : item.amount,
                            color : this.getRandomColor(i)
                        }
                    });
                    const dataInsightsManufacturersRebateTop = dataInsightsManufacturersRebate.slice(0, dataManufacturersRebate.initNumberManufacturers);
                    const allManufacturersRebate = dataInsightsManufacturersRebateTop.length >= dataInsightsManufacturersRebate.length ? true : false;
                    //Manufacturers - Deviation
                    const dataInsightsManufacturersDeviation = dataInsights.manufacturersDeviation.map((item, i)=> {
                        return {
                            ...item,
                            y : item.amount,
                            color : this.getRandomColor(i)
                        }
                    });
                    const dataInsightsManufacturersDeviationTop = dataInsightsManufacturersDeviation.slice(0, dataManufacturersDeviation.initNumberManufacturers);
                    const allManufacturersDeviation = dataInsightsManufacturersDeviationTop.length >= dataInsightsManufacturersDeviation.length ? true : false;

                    this.setState({
                        showInsideTrackModal: false,
                        dataInsights : dataInsights,
                        dataFound : (dataInsights.items.length > 0 || dataInsights.categories.length > 0 || dataInsights.distributors.length > 0 || dataInsights.manufacturersVolume.length > 0 || dataInsights.manufacturersRebate.length > 0) ? true : false,
                        dataOpportunities: {
                            ...dataOpportunities,
                            dataSummary: dataInsightsOpportunitiesSummary,
                        },
                        dataItems : {
                            ...this.state.dataItems,
                            dataItemsTop : dataInsightsItemsTop,
                            dataItemsAll : dataInsightsItems,
                            allItems : allItems
                        },
                        dataCategories : {
                            ...this.state.dataCategories,
                            dataCategoriesTop : dataInsightsCategoriesTop,
                            dataCategoriesAll : dataInsightsCategories,
                            allCategories : allCategories
                        },
                        dataDistributors : {
                            ...this.state.dataDistributors,
                            dataDistributorsTop : dataInsightsDistributorsTop,
                            dataDistributorsAll : dataInsightsDistributors,
                            allDistributors : allDistributors
                        },
                        dataManufacturers :  {
                            ...this.state.dataManufacturers,
                            dataManufacturersTop : dataInsightsManufacturersTop,
                            dataManufacturersAll : dataInsightsManufacturers,
                            allManufacturers : allManufacturers
                        },
                        dataManufacturersRebate : {
                            ...this.state.dataManufacturersRebate,
                            dataManufacturersTop : dataInsightsManufacturersRebateTop,
                            dataManufacturersAll :  dataInsightsManufacturersRebate,
                            allManufacturers : allManufacturersRebate
                        },
                        dataManufacturersDeviation : {
                            ...this.state.dataManufacturersDeviation,
                            dataManufacturersTop : dataInsightsManufacturersDeviationTop,
                            dataManufacturersAll :  dataInsightsManufacturersDeviation,
                            allManufacturers : allManufacturersDeviation
                        },
                        purchasedNonRebateItems: dataInsights.purchasedNonRebateItems,
                        purchasedRebateItems: dataInsights.purchasedRebateItems,
                    }, () => {
                        if (this.state.displayOpportunity) {
                            this.getOpportunityData(startDate, endDate);
                        }
                    });
                }).catch(function(error) {
                    console.error(error);
                    dispatch(spinnerImage(false, spin));
                    let errorMessage = "Something wen't wrong";
                    if(error && error.data) {
                        errorMessage = error.data.data.message;
                    }
                    dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', { message: errorMessage, button: "Ok"}));
                });
            });
        }).catch(err => {
            console.error(err);
        });
    };

    getOpportunityData = (startDate, endDate) => {
        const { dispatch, merchantAll } = this.props;
        const { dataInsights, dataOpportunities, merchantSelected } = this.state;

        this.setState({
            dataOpportunities: {
                ...dataOpportunities,
                loading: true,
            }
        });

        const itemsWithNoRebates = dataInsights.items.filter(i => !i.rebate).map(i => ({
            manufacturerPlatformId: i.manufacturerPlatformId,
            distributorPlatformId: i.distributorPlatformId,
            min: i.min.trim(),
            quantity: i.quantity,
            amount: i.amount,
            rebate: i.rebate,
            dcn: i.dcn.trim(),
            product_id: i.product_id,
            manufactuer_product_id: i.manufactuer_product_id,
            packsize: i.packsize
        })).slice(0, 25);

        const platformIds = merchantSelected.length === 0 ? merchantAll.map(m => m.platformId) : merchantSelected.map(m => m.platformId);
        const uniquePlatformIds = platformIds.filter((v) => v !== null).filter((v, i, s) => {
            return s.indexOf(v) === i;
        });

        InsightsApi.getOpportunity(startDate, endDate, itemsWithNoRebates, uniquePlatformIds).then(({data}) => {
            const result = data.data.items;

            const dataInsightsOpportunitiesAll = result.map(d => {
                return {
                    original: {
                        ...d.original,
                        productId: d.original.product_id,
                        mfr: d.original.manufacturer || '',
                        description: (d.original.description && d.original.description.toLowerCase()) || '',
                        qty: d.original.quantity
                    },
                    match: {
                        ...d.match,
                        productId: d.match.product_id,
                        mfr: d.match.manufacturer || '',
                        description: (d.match.description && d.match.description.toLowerCase()) || '',
                        qty: d.match.quantity,
                        annualRebate: d.match.annualSaving
                    }
                };
            });
            this.setState({
                dataInsights: {
                    ...dataInsights,
                    potentialOpportunity: data.data.total
                },
                dataOpportunities: {
                    ...dataOpportunities,
                    loading: false,
                    dataItemsAll: dataInsightsOpportunitiesAll
                }
            });
        }).catch((error) => {
            console.error(error);
            let errorMessage = "Something wen't wrong";
            if(error && error.data) {
                errorMessage = error.data.data.message;
            }
            this.setState({
                dataOpportunities: {
                    ...dataOpportunities,
                    loading: false,
                }
            });
            dispatch(showModal(VerifyRespondModal, 'verifyRespondModal', { message: errorMessage, button: "Ok"}));
        });
    }

    getRandomColor = (i) => {
        const color = config.colorPalette;
        if(i > color.length) {
            let letters = '0123456789ABCDEF'.split('');
            let colorNew = '#';
            for (let j = 0; j < 6; j++) {
                colorNew += letters[Math.floor(Math.random() * 16)];
            }
            return colorNew;
        }
        return color[i]
    }

    toggleOpportunities = () => {
        if (isMobile) {
            const { merchantSelected, datePeriod } = this.state;
            const { dispatch, merchantAll } = this.props;

            const platformIds = merchantSelected.length === 0 ? merchantAll.map(m => m.platformId) : merchantSelected.map(m => m.platformId);
            const uniquePlatformIds = platformIds.filter((v) => v !== null).filter((v, i, s) => {
                return s.indexOf(v) === i;
            });

            this.props.dispatch(showModal(InsightsBodyOpportunitiesModal, 'InsightsBodyOpportunitiesModal', {
                ...this.state.dataOpportunities,
                dispatch,
                refresh: this.getOpportunityData,
                datePeriod: datePeriod,
                uniquePlatformIds
            }));
        } else {
            this.setState({
                dataOpportunities: {
                    ...this.state.dataOpportunities,
                    show: !this.state.dataOpportunities.show
                }
            });
        }
    }

    showMoreItems = () => {
        const {dataItemsAll, numberItems, initNumberItems} = this.state.dataItems;
        const newDataItems = dataItemsAll.slice(0, numberItems + initNumberItems);
        const allItems = newDataItems.length >= dataItemsAll.length ? true : false;
            this.setState({
                dataItems : {
                    ...this.state.dataItems,
                    dataItemsTop : newDataItems,
                    allItems : allItems,
                    numberItems : numberItems + initNumberItems
                }
            });
    }

    showLessItems = () => {
        const { dataItemsAll, numberItems, initNumberItems} = this.state.dataItems;
        const newDataItems = dataItemsAll.slice(0, numberItems - initNumberItems);
        const allItems = newDataItems.length >= dataItemsAll.length ? true : false;
        this.setState({
            dataItems : {
                ...this.state.dataItems,
                dataItemsTop : newDataItems,
                allItems : allItems,
                numberItems : numberItems - initNumberItems
            }
        });
    }

    showMoreCategories = () => {
        const {dataCategoriesAll, numberCategories, initNumberCategories} = this.state.dataCategories;
        const newDataCategories = dataCategoriesAll.slice(0, numberCategories + initNumberCategories);
        const allCategories = newDataCategories.length >= dataCategoriesAll.length ? true : false;
        this.setState({
            dataCategories : {
                ...this.state.dataCategories,
                dataCategoriesTop : newDataCategories,
                allCategories : allCategories,
                numberCategories : numberCategories + initNumberCategories
            },
        });
    }

    showLessCategories = () => {
        const {dataCategoriesAll, numberCategories, initNumberCategories} = this.state.dataCategories;
        const newDataCategories = dataCategoriesAll.slice(0, numberCategories - initNumberCategories);
        const allCategories = newDataCategories.length >= dataCategoriesAll.length ? true : false;
        this.setState({
            dataCategories : {
                ...this.state.dataCategories,
                dataCategoriesTop : newDataCategories,
                allCategories : allCategories,
                numberCategories : numberCategories - initNumberCategories
            },
        });
    }

    showMoreDistributors = () => {
        const {dataDistributorsAll, numberDistributors, initNumberDistributors} = this.state.dataDistributors;
        const newDataDistributors = dataDistributorsAll.slice(0, numberDistributors + initNumberDistributors);
        const allDistributors = newDataDistributors.length >= dataDistributorsAll.length ? true : false;
        this.setState({
            dataDistributors : {
                ...this.state.dataDistributors,
                dataDistributorsTop : newDataDistributors,
                allDistributors : allDistributors,
                numberDistributors : numberDistributors + initNumberDistributors
            },
        });
    }

    showLessDistributors = () => {
        const {dataDistributorsAll, numberDistributors, initNumberDistributors} = this.state.dataDistributors;
        const newDataDistributors = dataDistributorsAll.slice(0, numberDistributors - initNumberDistributors);
        const allDistributors = newDataDistributors.length >= dataDistributorsAll.length ? true : false;
        this.setState({
            dataDistributors : {
                ...this.state.dataDistributors,
                dataDistributorsTop : newDataDistributors,
                allDistributors : allDistributors,
                numberDistributors : numberDistributors - initNumberDistributors
            },
        });
    }

    showMoreManufacturers = (tabValue) => {
        const { dataManufacturers, dataManufacturersRebate, dataManufacturersDeviation } = this.state;
        if(tabValue === 'volume') {
            const {dataManufacturersAll, numberManufacturers, initNumberManufacturers} = dataManufacturers;
            const newDataManufacturers = dataManufacturersAll.slice(0, numberManufacturers + initNumberManufacturers);
            const allManufacturers = newDataManufacturers.length >= dataManufacturersAll.length ? true : false;
            this.setState({
                dataManufacturers : {
                    ...dataManufacturers,
                    dataManufacturersTop : newDataManufacturers,
                    allManufacturers : allManufacturers,
                    numberManufacturers : numberManufacturers + initNumberManufacturers
                },
            });
        } else if (tabValue === 'rebate') {
            const {dataManufacturersAll, numberManufacturers, initNumberManufacturers} = dataManufacturersRebate;
            const newDataManufacturers = dataManufacturersAll.slice(0, numberManufacturers + initNumberManufacturers);
            const allManufacturers = newDataManufacturers.length >= dataManufacturersAll.length ? true : false;
            this.setState({
                dataManufacturersRebate : {
                    ...dataManufacturersRebate,
                    dataManufacturersTop : newDataManufacturers,
                    allManufacturers : allManufacturers,
                    numberManufacturers : numberManufacturers + initNumberManufacturers
                },
            });
        } else {
            const {dataManufacturersAll, numberManufacturers, initNumberManufacturers} = dataManufacturersDeviation;
            const newDataManufacturers = dataManufacturersAll.slice(0, numberManufacturers + initNumberManufacturers);
            const allManufacturers = newDataManufacturers.length >= dataManufacturersAll.length ? true : false;
            this.setState({
                dataManufacturersDeviation : {
                    ...dataManufacturersDeviation,
                    dataManufacturersTop : newDataManufacturers,
                    allManufacturers : allManufacturers,
                    numberManufacturers : numberManufacturers + initNumberManufacturers
                },
            });
        }
    }

    showLessManufacturers = (tabValue) => {
        const { dataManufacturers, dataManufacturersRebate, dataManufacturersDeviation } = this.state;
        if(tabValue === 'volume') {
            const {dataManufacturersAll, numberManufacturers, initNumberManufacturers} = dataManufacturers;
            const newDataManufacturers = dataManufacturersAll.slice(0, numberManufacturers - initNumberManufacturers);
            const allManufacturers = newDataManufacturers.length >= dataManufacturersAll.length ? true : false;
            this.setState({
                dataManufacturers : {
                    ...dataManufacturers,
                    dataManufacturersTop : newDataManufacturers,
                    allManufacturers : allManufacturers,
                    numberManufacturers : numberManufacturers - initNumberManufacturers
                },
            });
        } else if (tabValue === 'rebate') {
            const {dataManufacturersAll, numberManufacturers, initNumberManufacturers} = dataManufacturersRebate;
            const newDataManufacturers = dataManufacturersAll.slice(0, numberManufacturers - initNumberManufacturers);
            const allManufacturers = newDataManufacturers.length >= dataManufacturersAll.length ? true : false;
            this.setState({
                dataManufacturersRebate : {
                    ...dataManufacturersRebate,
                    dataManufacturersTop : newDataManufacturers,
                    allManufacturers : allManufacturers,
                    numberManufacturers : numberManufacturers - initNumberManufacturers
                },
            });
        } else {
            const {dataManufacturersAll, numberManufacturers, initNumberManufacturers} = dataManufacturersDeviation;
            const newDataManufacturers = dataManufacturersAll.slice(0, numberManufacturers - initNumberManufacturers);
            const allManufacturers = newDataManufacturers.length >= dataManufacturersAll.length ? true : false;
            this.setState({
                dataManufacturersDeviation : {
                    ...dataManufacturersDeviation,
                    dataManufacturersTop : newDataManufacturers,
                    allManufacturers : allManufacturers,
                    numberManufacturers : numberManufacturers - initNumberManufacturers
                },
            });
        }
    }

    onTabManufacturer = (data) => {
        switch(data) {
            case 'volume' :
                this.setState({
                    tabManufacturers : {
                        isSelectedVolume : true,
                        isSelectedRebate : false,
                        isSelectedDeviation: false
                    }
                });
                break;
            case 'rebate' :
                this.setState({
                    tabManufacturers : {
                        isSelectedVolume : false,
                        isSelectedRebate : true,
                        isSelectedDeviation: false
                    }
                });
                break;
            case 'deviation' :
                this.setState({
                    tabManufacturers : {
                        isSelectedVolume : false,
                        isSelectedRebate : false,
                        isSelectedDeviation: true
                    }
                });
                break;
            default:
                break;
        }
    }

    onClickTabData = (data) => {
        switch(data) {
            case 'items' :
                    this.setState({
                        tabData : {
                            isSelectedItems : true,
                            isSelectedManufacturers : false,
                            isSelectedDistributors : false,
                            isSelectedCategories : false,
                        }
                    });
                    this.slider.slickGoTo(0);
                break;
            case 'manufacturers' :
                this.setState({
                    tabData : {
                        isSelectedItems : false,
                        isSelectedManufacturers : true,
                        isSelectedDistributors : false,
                        isSelectedCategories : false
                    }
                });
                this.slider.slickGoTo(1);
                break;
            case 'distributors' :
                    this.setState({
                        tabData : {
                            isSelectedItems : false,
                            isSelectedCategories : false,
                            isSelectedDistributors : true,
                            isSelectedManufacturers : false
                        }
                    });
                    this.slider.slickGoTo(2);
                break;
            case 'categories' :
                    this.setState({
                        tabData : {
                            isSelectedItems : false,
                            isSelectedManufacturers : false,
                            isSelectedDistributors : false,
                            isSelectedCategories : true
                        }
                    });
                    this.slider.slickGoTo(3);
                break;
            default:
                   break;
        }
    }

    onClickPeriodTime = (period) => {
        const today = new Date();
        const format = 'YYYY-MM-DD';
        switch(period) {
            case 'week' :
                    const startDateWeek = moment(new Date()).subtract(6, 'days').format(format);
                    const endDateWeek = moment(new Date()).format(format)
                    this.getData(startDateWeek, endDateWeek);
                    this.setState({
                        period : {
                            isWeekActivated : true,
                            isMonthActivated : false,
                            isYearActivated : false,
                            isCustomActivated : false,
                        },
                        datePeriod : {
                            startDate : startDateWeek,
                            endDate : endDateWeek
                        }
                    });
                break;
            case 'month' :
                    const LastDay = moment(new Date(today.getFullYear(), today.getMonth(), 0)).format('DD');
                    const startDateMonth = moment(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                    const endDateMonth= moment(new Date(today.getFullYear(), today.getMonth()-1, LastDay));
                    this.getData(startDateMonth.format(format), endDateMonth.format(format));
                    this.setState({
                        period : {
                            isWeekActivated : false,
                            isMonthActivated : true,
                            isYearActivated : false,
                            isCustomActivated : false,
                        },
                        datePeriod : {
                            startDate : startDateMonth.format('MM/DD/YYYY'),
                            endDate : endDateMonth.format('MM/DD/YYYY')
                        }
                    });
                break;
            case 'year' :
                    const startDateYear = moment(new Date(today.getFullYear(), 0, 1));
                    const endDateYear = moment(today);
                    this.getData(startDateYear.format(format), endDateYear.format(format));
                    this.setState({
                        period : {
                            isWeekActivated : false,
                            isMonthActivated : false,
                            isYearActivated : true,
                            isCustomActivated : false,
                        },
                        datePeriod : {
                            startDate : startDateYear.format('MM/DD/YYYY'),
                            endDate : endDateYear.format('MM/DD/YYYY')
                        }
                    });
                break;
            case 'custom' :
                    const { dispatch } = this.props;
                    dispatch(showModal(InsightsDateRangeModal, 'idInsightsDateRangeModal', { saveInsightsDateRange : this.saveInsightsDateRange}));
                    this.setState({
                        period : {
                            isWeekActivated : false,
                            isMonthActivated : false,
                            isYearActivated : false,
                            isCustomActivated : true,
                        }
                    });
                break;
            default:
                   break;
        }
    }

    saveInsightsDateRange = (startDatVal, endDateVal) => {
        const format = 'YYYY-MM-DD';
        const startDate = moment(startDatVal);
        const endDate = moment(endDateVal);
        this.getData(startDate.format(format), endDate.format(format));
        this.setState({
            datePeriod : {
                startDate : startDate.format('MM/DD/YYYY'),
                endDate : endDate.format('MM/DD/YYYY')
            }
        });
    }

    goToAddNewLocation = () => {
        const { history, dispatch } = this.props;
        ReactGA.event({
          category: 'Click Button',
          action: 'Click Add New Location'
        });
        history.push('/dashboard/add-new-location');
    }

    InsightsManageLocations = () => {
        this.resetStates();
        this.onClickPeriodTime('year');
    }

    clickManageLocations = () => {
        const { dispatch, locations, history, refresh, activeMerchant, empty } = this.props;
        dispatch(showModal(ManageLocationsModal, 'manageLocationsModal', { goToAdd: this.goToAddNewLocation, locations: locations, activeMerchant: activeMerchant, refresh: refresh, empty: empty, dispatch: dispatch, history: history, InsightsManageLocations : this.InsightsManageLocations }));
    }

    toggleLocation = (loc) => {
        const { merchantSelected } = this.state;
        const alreadyAdded = merchantSelected.some(m => m.id === loc.id);
        this.setState({
            merchantSelected: alreadyAdded ? merchantSelected.filter(m => m.id !== loc.id) : [...merchantSelected, loc]
        }, () => {
            const { merchantSelected } = this.state;
            const { merchantAll } = this.props;
            if (merchantAll.length === merchantSelected.length) {
                this.toggleAllLocation();
            }
        });
    };

    toggleAllLocation = () => {
        this.setState({
            merchantSelected: []
        });
    };

    goToEditLocation = (id) => {
        const { history } = this.props;
        ReactGA.event({
            category: 'Edit Location',
            action: 'Click Edit'
        });
        history.push(`/dashboard/edit-location/${id}`);
    }

    goToDeleteLocation = (id) => {
        const { dispatch, activeMerchant } = this.props;
        if (activeMerchant.id === id) {
            const props = {
            message : `Can't delete active location.`,
            buttonText: 'Ok'
            }
            dispatch(showModal(AlertModal, 'idAlertModal', {...props}));
        } else {
            dispatch(showModal(LocationMangeModalDelete, 'idLocationMangeModalDelete', {id: id, acceptDelete: this.acceptDelete}));
        }
    }

    triggerSearch = () => {
        const { datePeriod, merchantSelected } = this.state;
        const format = 'YYYY-MM-DD';
        const startDate = moment(datePeriod.startDate);
        const endDate = moment(datePeriod.endDate);

        if (merchantSelected.length === 1) {
            this.getData(startDate.format(format), endDate.format(format));
        } else {
            this.getData(startDate.format(format), endDate.format(format));
        }
    };

    downloadLineItems = () => {
        const { merchantAll } = this.props;
        const { datePeriod, merchantSelected } = this.state;

        const platformIds = merchantSelected.length === 0 ? merchantAll.map(m => m.platformId) : merchantSelected.map(m => m.platformId);
        const uniquePlatformIds = platformIds.filter((v) => v !== null).filter((v, i, s) => {
            return s.indexOf(v) === i;
        });

        const format = 'YYYY-MM-DD';
        const startDate = moment(datePeriod.startDate).format(format);
        const endDate = moment(datePeriod.endDate).format(format);

        InsightsApi.downloadInsights(startDate, endDate, uniquePlatformIds).then(({data}) => {
            window.open(data.data);
        }).catch(err => {
            console.error();
        });
    };

    render() {
        const { merchantAll, companyId, modals } = this.props;
        const {
            merchantSelected, dataInsights, dataItems,
            dataCategories, dataDistributors, dataManufacturers,
            dataManufacturersDeviation, period, datePeriod,
            tabData, dataFound, tabManufacturers,
            dataManufacturersRebate, dataOpportunities,
            purchasedNonRebateItems, purchasedRebateItems
        } = this.state;
        const textLinkColor = getTextLinkColor(companyId);
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                switch(next) {
                    case 0 :
                        this.setState({
                            tabData : {
                                isSelectedItems : true,
                                isSelectedManufacturers : false,
                                isSelectedDistributors : false,
                                isSelectedCategories : false
                            }
                        });
                        break;
                    case 1 :
                            this.setState({
                                tabData : {
                                    isSelectedItems : false,
                                    isSelectedManufacturers : true,
                                    isSelectedDistributors : false,
                                    isSelectedCategories : false,
                                }
                            });
                            break;
                    case 2 :
                            this.setState({
                                tabData : {
                                    isSelectedItems : false,
                                    isSelectedManufacturers : false,
                                    isSelectedDistributors : true,
                                    isSelectedCategories : false
                                }
                            });
                            break;
                    case 3 :
                        this.setState({
                            tabData : {
                                isSelectedItems : false,
                                isSelectedManufacturers : false,
                                isSelectedDistributors : false,
                                isSelectedCategories : true
                            }
                        });
                        break;
                  default :
                      break;
                }
            }
        };

        const propsManufacturer = {
            dataManufacturers: dataManufacturers,
            dataManufacturersRebate: dataManufacturersRebate,
            dataManufacturersDeviation: dataManufacturersDeviation,
            tabManufacturers: tabManufacturers,
            tabData: tabData,
            showMoreManufacturers: this.showMoreManufacturers,
            showLessManufacturers: this.showLessManufacturers,
            onTabManufacturer: this.onTabManufacturer
        }

        const propsLocationManage = {
            modals,
            goToAdd: this.goToAddNewLocation,
            toggleLocation: this.toggleLocation,
            goToEdit: this.goToEditLocation,
            goToDelete: this.goToDeleteLocation,
            merchantSelected: merchantSelected,
            toggleAllLocation: this.toggleAllLocation,
            triggerSearch: this.triggerSearch,
            options: merchantAll.map(m => {
                return {
                    id: m.id,
                    label: m.nickname,
                    platformId: m.platformId
                }
            })
        };

        const platformIds = merchantSelected.length === 0 ? merchantAll.map(m => m.platformId) : merchantSelected.map(m => m.platformId);
        const uniquePlatformIds = platformIds.filter((v) => v !== null).filter((v, i, s) => {
            return s.indexOf(v) === i;
        });

        if (isMobile) {
            return (
                <Fragment>
                    <GridRow className='m-0 align-items-center mt-3'>
                        <GridCol maxWidth='fit-content' className='text-grey'>
                            Location:
                        </GridCol>
                        <GridCol>
                            <LocationDropdown {...propsLocationManage} hideAdd={companyId === 3 || companyId === 17} />
                        </GridCol>
                    </GridRow>
                    <GridRow className='m-0 p-2'>
                        <GridCol className='insights-box mt-2 rounded'>
                            <GridRow className='text-center py-2'>
                                <GridCol className='text-center text-grey'>
                                    {datePeriod.startDate} - {datePeriod.endDate}
                                </GridCol>
                            </GridRow>
                            <GridRow className='text-center py-2'>
                                <GridCol className='text-center insights-graph-title'>
                                    Highlights
                                </GridCol>
                            </GridRow>
                            <GridRow className='text-center py-3'>
                                <GridCol className='text-center insights-text-period text-size-13' style={{color: textLinkColor}}>
                                    This information is an estimate. It may or may not include rebates that you have already been paid.
                                </GridCol>
                            </GridRow>
                            <GridRow className='mb-3 text-center text-uppercase insights-box insights-text-period'>
                                <GridCol className={period.isMonthActivated ? 'py-3 insights-actived-line insights-actived-text' : 'py-3'} onClick={this.onClickPeriodTime.bind(this, 'month')}>
                                    Previous Month
                                </GridCol>
                                <GridCol className={period.isYearActivated ? 'py-3 insights-actived-line insights-actived-text' : 'py-3'} onClick={this.onClickPeriodTime.bind(this, 'year')}>
                                    Year to Date
                                </GridCol>
                                <GridCol className={period.isCustomActivated ? 'py-3 insights-actived-line insights-actived-text' : 'py-3'} onClick={this.onClickPeriodTime.bind(this, 'custom')}>
                                    Custom
                                </GridCol>
                            </GridRow>
                            <InsightsBodyOverallStats dataInsights={dataInsights} />
                        </GridCol>
                    </GridRow>
                    <GridRow className='m-0 p-2'>
                        <GridCol className='insights-box mt-2 rounded'>
                            <GridRow>
                                <InsightsBodyOpportunities toggleOpportunities={this.toggleOpportunities} {...dataOpportunities} dispatch={this.props.dispatch} displayOpportunity={this.state.displayOpportunity} purchasedRebateItems={purchasedRebateItems || []} purchasedNonRebateItems={purchasedNonRebateItems || []}/>
                            </GridRow>
                        </GridCol>
                    </GridRow>
                    <GridRow className='m-0 p-2'>
                        <GridCol className='insights-box mt-2 rounded'>
                            { dataFound ?
                                <Fragment>
                                    <Slider  ref={slider => (this.slider = slider)} {...settings}>
                                        <div>
                                            <InsightsBodyItems  dataItems = {dataItems}  tabData = {tabData} showMoreItems = {this.showMoreItems} showLessItems={this.showLessItems} />
                                        </div>
                                        <div>
                                            {  tabManufacturers.isSelectedVolume ?
                                                <InsightsBodyManufacturers {...propsManufacturer}  />
                                                :
                                                tabManufacturers.isSelectedRebate &&
                                                <InsightsBodyManufacturersRebate {...propsManufacturer} />
                                            }
                                        </div>
                                        <div>
                                            <InsightsBodyDistributors dataDistributors = {dataDistributors} tabData = {tabData} showMoreDistributors = {this.showMoreDistributors} showLessDistributors={this.showLessDistributors} />
                                        </div>
                                        <div>
                                            <InsightsBodyCategories dataCategories = {dataCategories} tabData = {tabData} showMoreCategories = {this.showMoreCategories} showLessCategories={this.showLessCategories} />
                                        </div>
                                    </Slider>
                                    <GridRow className='insights-box text-center border-bottom-0'>
                                        <GridCol className={'py-3 px-0 col-3 text-size-12 ' +  (tabData.isSelectedItems ? 'py-3 insights-actived-line-top insights-actived-text' : '')} onClick={this.onClickTabData.bind(this, 'items')}>
                                            Top Items
                                        </GridCol>
                                        <GridCol className={'py-3 px-0 col-3 text-size-12 ' + (tabData.isSelectedManufacturers ? 'py-3 insights-actived-line-top insights-actived-text' : '')} onClick={this.onClickTabData.bind(this, 'manufacturers')}>
                                            Manufacturers
                                        </GridCol>
                                        <GridCol className={'py-3 px-0 col-3 text-size-12 ' + (tabData.isSelectedDistributors ? 'py-3 insights-actived-line-top insights-actived-text' : '')} onClick={this.onClickTabData.bind(this, 'distributors')}>
                                            Distributors
                                        </GridCol>
                                        <GridCol className={'py-3 px-0 col-3 text-size-12 ' + (tabData.isSelectedCategories ? 'py-3 insights-actived-line-top insights-actived-text' : '')} onClick={this.onClickTabData.bind(this, 'categories')}>
                                            Categories
                                        </GridCol>
                                    </GridRow>
                                </Fragment>
                                :
                                <InsightsEmpty />
                            }
                        </GridCol>
                    </GridRow>
                </Fragment>
            )
        }

        const isAnalyst = localStorage.getItem('authKeyAnalyst');

        return (
            <Fragment>
                <GridRow className='py-3 insights-highlights-text'>
                    <GridCol className='pl-4 text-grey text-left' style={{fontSize:'14px'}}>
                        {datePeriod.startDate} - {datePeriod.endDate}
                    </GridCol>
                    {
                        isAnalyst ?
                            <GridCol className='text-right'>
                                <Button onClick={this.downloadLineItems} outline block className='py-2 shadow-none btn-rb-yellow' size='sm' color='' >Download</Button>
                            </GridCol>
                        : null
                    }
                </GridRow>
                <GridRow className='insights-line-bottom clickable align-items-center'>
                    <GridCol className='pl-4 insights-text-period line-height-15 text-size-14' style={{color: textLinkColor, letterSpacing: '0px'}}>
                        This information is an estimate. It may or may not include rebates that you have already been paid.
                    </GridCol>
                    <GridCol maxWidth='fit-content' className='text-grey'>
                        Location:
                    </GridCol>
                    <GridCol size={3} className="location-dropdown-col">
                        <LocationDropdown {...propsLocationManage} hideAdd={companyId === 3 || companyId === 17} />
                    </GridCol>
                    <GridCol size={3} className='text-uppercase insights-text-period tabs-col'>
                        <GridRow className='text-center'>
                            <GridCol className={'py-4 px-2 ' + (period.isMonthActivated ? 'insights-actived-line insights-actived-text' : '')}  onClick={this.onClickPeriodTime.bind(this, 'month')}>
                                Previous Month
                            </GridCol>
                            <GridCol className={'py-4 px-2 ' + (period.isYearActivated ? 'insights-actived-line insights-actived-text' : '')}  onClick={this.onClickPeriodTime.bind(this, 'year')}>
                                Year to Date
                            </GridCol>
                            <GridCol className={'py-4 px-2 ' + (period.isCustomActivated ? 'pb-2 insights-actived-line insights-actived-text' : '')} onClick={this.onClickPeriodTime.bind(this, 'custom')}>
                                Custom
                            </GridCol>
                        </GridRow>
                    </GridCol>
                </GridRow>

                { dataFound ?
                    <Fragment>
                        <GridRow className='insights-line-bottom'>
                            <InsightsBodyOverallStats dataInsights={dataInsights} />
                            <InsightsBodyOpportunities toggleOpportunities={this.toggleOpportunities} {...dataOpportunities} displayOpportunity={this.state.displayOpportunity} purchasedRebateItems={purchasedRebateItems || []} purchasedNonRebateItems={purchasedNonRebateItems || []}/>
                        </GridRow>
                        {
                            (this.state.dataOpportunities.show && (this.state.dataOpportunities.dataItemsAll.length > 0 || this.state.dataOpportunities.loading) && this.state.displayOpportunity) &&
                            <InsightsBodyShowOpportunities {...dataOpportunities} dispatch={this.props.dispatch} refresh={this.getOpportunityData} datePeriod={this.state.datePeriod} platformIds={uniquePlatformIds} />
                        }
                        <GridRow className='text-center insights-line-bottom'>
                            <InsightsBodyItems dataItems = {dataItems} showMoreItems = {this.showMoreItems} showLessItems={this.showLessItems} />
                            {  tabManufacturers.isSelectedVolume ?
                                <InsightsBodyManufacturers {...propsManufacturer}  />
                                :
                                tabManufacturers.isSelectedRebate ?
                                <InsightsBodyManufacturersRebate {...propsManufacturer} />
                                : 
                                <InsightsBodyManufacturersDeviation {...propsManufacturer} />
                            }
                        </GridRow>
                        <GridRow>
                            <InsightsBodyDistributors dataDistributors = {dataDistributors} showMoreDistributors = {this.showMoreDistributors} showLessDistributors = {this.showLessDistributors} />
                            <InsightsBodyCategories dataCategories = {dataCategories} showMoreCategories = {this.showMoreCategories} showLessCategories = {this.showLessCategories} />
                        </GridRow>
                    </Fragment>
                :
                    <InsightsEmpty />
                }

            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        enableToOpenInsights: state.dashboard.enableToOpenInsights,
        spinnerLoader: state.spinner.spinnerLoader,
        displayInsight: state.company.displayInsight,
        merchantAll: state.location.merchantFilter.filter(m => m.platformId),
        companyId: state.company.id,
        modals: state.modals.modals
    };
}

export default connect(mapStateToProps)(InsightsBody);
