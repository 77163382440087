import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insightsSetAll, insightsSetOpportunity } from '../Reducer/HomeMainAction';
import { showModal } from '../../../MasterComponents/Modals/ModalsActions';
import { spinnerImage } from '../../../MasterComponents/Spinner/SpinnerActions';
import InsightsApi from '../../Insights/InsightsApi';
import InsightsDateRangeModal from '../../Insights/Modals/InsightsDateRangeModal';
import config from '../../../config/config';
import moment from 'moment';

const useHomeInsights = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const merchants = useSelector(({location}) => location.merchant);
  const userCurrent = useSelector(({location}) => location.userCurrent);
  const insightsAll = useSelector(({homeMain}) => homeMain.insightsAll);
  const spinnerLoader = useSelector(({spinner}) => spinner.spinnerLoader);
  const insightsOpportunity = useSelector(({homeMain}) => homeMain.insightsOpportunity);
  const format = 'YYYY-MM-DD';
  const formatView = 'MM/DD/YYYY';
  const today = new Date();
  const startDateYear = moment(new Date(today.getFullYear(), 0, 1));
  const endDateYear = moment(today);
  const [insightsState, setinsightsState] = useState({
    period: {
      isMonthActivated: true,
      isYearActivated: false,
      isCustomActivated: false,
    },
    datePeriod: {
      startDate: startDateYear.format(formatView),
      endDate: endDateYear.format(formatView)
    }
  });

  useEffect(()=> {
    if  (merchants.length > 0) {
        onPeriodTime('month');
    }
  }, [merchants.length > 0]);

  const getRandomColor = (i) => {
    const color = config.colorPalette;
    if(i > color.length) {
      const letters = '0123456789ABCDEF'.split('');
      let colorNew = '#';
      for (let j = 0; j < 6; j++) {
        colorNew += letters[Math.floor(Math.random() * 16)];
      }
      return colorNew;
    }
    return color[i]
  }

  const displayDeviations = userCurrent.displayDeviations === 1;

  const insightsOpportunitySummary = [{
    amount: displayDeviations ? (insightsAll.purchasesPercentages && insightsAll.purchasesPercentages[1]) : insightsAll.totalPurchasePercentage,
    y: displayDeviations ? (insightsAll.purchasesPercentages && insightsAll.purchasesPercentages[1]) : insightsAll.totalPurchasePercentage,
    color : getRandomColor(1),
    description: 'Purchases without rebates and/or Deviations'
  }, {
    amount: displayDeviations ? (insightsAll.purchasesPercentages && insightsAll.purchasesPercentages[0]) : insightsAll.totalRebatePercentage,
    y: displayDeviations ? (insightsAll.purchasesPercentages && insightsAll.purchasesPercentages[0]) : insightsAll.totalRebatePercentage,
    color : getRandomColor(0),
    description: 'Purchases with rebates and/or Deviations'
  }];

  const onGoToInsights = () => {
    history.push('/dashboard/insights');
  }

  const getSpinnerLoader = (spinner) => {
    if(spinner > 4) return 1
    return spinner + 1;
  }

  const getData = (startDate, endDate) => {
    const platformIds = merchants.map(m => m.platformId);
    const uniquePlatformIds = platformIds.filter((v) => v !== null).filter((v, i, s) => {
      return s.indexOf(v) === i;
    });

    const spin = getSpinnerLoader(spinnerLoader)
    dispatch(spinnerImage(true, spin));
    InsightsApi.getInsights(startDate, endDate, uniquePlatformIds).then(({data}) => {
      dispatch(spinnerImage(false, spin));
      const dataInsights = data.data;
      dispatch(insightsSetAll(dataInsights));
      const itemsWithNoRebates = dataInsights.items.filter(i => !i.rebate).map(i => ({
        manufacturerPlatformId: i.manufacturerPlatformId,
        distributorPlatformId: i.distributorPlatformId,
        min: i.min.trim(),
        quantity: i.quantity,
        amount: i.amount,
        rebate: i.rebate,
        dcn: i.dcn.trim(),
        product_id: i.product_id,
        manufactuer_product_id: i.manufactuer_product_id,
        packsize: i.packsize
      })).slice(0, 25);
      InsightsApi.getOpportunity(startDate, endDate, itemsWithNoRebates, uniquePlatformIds).then(({data}) => {
        dispatch(insightsSetOpportunity(data.data));
      });
    }).catch(err => {
      dispatch(spinnerImage(false, spin));
      console.error(err);
    });
  }

  const onPeriodTime = (period) => {
    switch(period) {
      case 'month' :
        const LastDay = moment(new Date(today.getFullYear(), today.getMonth(), 0)).format('DD');
        const startDateMonth = moment(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        const endDateMonth= moment(new Date(today.getFullYear(), today.getMonth()-1, LastDay));
        getData(startDateMonth.format(format), endDateMonth.format(format));
        setinsightsState({
          ...insightsState,
          period : {
            isWeekActivated : false,
            isMonthActivated : true,
            isYearActivated : false,
            isCustomActivated : false,
          },
          datePeriod : {
            startDate : startDateMonth.format(formatView),
            endDate : endDateMonth.format(formatView)
          }
        });
        break;
      case 'year' :
        const startDateYear = moment(new Date(today.getFullYear(), 0, 1));
        const endDateYear = moment(today);
        getData(startDateYear.format(format), endDateYear.format(format));
        setinsightsState({
          ...insightsState,
          period : {
            isMonthActivated : false,
            isYearActivated : true,
            isCustomActivated : false,
          },
          datePeriod : {
            startDate : startDateYear.format(formatView),
            endDate : endDateYear.format(formatView)
          }
        });
        break;
      case 'custom' :
        dispatch(showModal(InsightsDateRangeModal, 'idInsightsDateRangeModal', { saveInsightsDateRange : saveInsightsDateRange}));
        setinsightsState({
          ...insightsState,
          period : {
            isMonthActivated: false,
            isYearActivated: false,
            isCustomActivated: true,
          }
        });
        break;
      default:
        break;
    }
  }

  const saveInsightsDateRange = (startDatVal, endDateVal) =>{
    const startDate = moment(startDatVal);
    const endDate = moment(endDateVal);
    getData(startDate.format(format), endDate.format(format));
    setinsightsState({
      ...insightsState,
      period : {
        isMonthActivated: false,
        isYearActivated: false,
        isCustomActivated: true,
      },
      datePeriod : {
        startDate : startDate.format(formatView),
        endDate : endDate.format(formatView)
      }
    });
  }

  return {
    insightsAll,
    insightsOpportunity,
    insightsOpportunitySummary,
    insightsState,
    userCurrent,
    onGoToInsights,
    onPeriodTime
  }
}

export default useHomeInsights
