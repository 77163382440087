import React from 'react';
import { Row, Col } from 'reactstrap';
import { VscCircleFilled } from 'react-icons/vsc';
import moment from 'moment';

const ContractTableRow = (contract) => {
	const { manufacturer, status, startDate, endDate, onUpdate, disabledActions } = contract;
	const color = status === 'Active' ? 'success' : (status === 'Expiring' ? 'warning' : 'danger');
	return (
		<Row className='py-3 border-bottom text-size-14 line-height-16'>
			<Col>
				{manufacturer}
			</Col>
			<Col className={`text-${color} text-capitalize`}>
				<VscCircleFilled className='pr-1' size={20} />
				{status}
			</Col>
			<Col xs={3}>
				{moment(startDate).format('MM/DD/yyyy')}
			</Col>
			<Col xs={status.toLowerCase() !== 'expired' ? 2 : 3}>
				{endDate ? moment(endDate).format('MM/DD/yyyy') : '-'}
			</Col>
			{
				status.toLowerCase() !== 'expired' &&
				<Col xs={1} className={`text-primary${disabledActions ? '-50' : ''} clickable`} onClick={() => {if (!disabledActions) {onUpdate(contract)}}}>
					Update
				</Col>
			}
		</Row>
	);
};

export default ContractTableRow;