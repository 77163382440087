import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import './Dashboard.scss';
import { changePage, saveUsers, saveMerchants } from './DashboardActions';
import { spinner } from '../../MasterComponents/Spinner/SpinnerActions';
import DashboardSettingsChangePassword from './DashboardSettingsChangePassword';
import DashboardSettingsUsers from './DashboardSettingsUsers';
import Tabs from '../../MasterComponents/Tabs/Tabs';
import DashboardApi from './DashboardApi';
import GridRow from '../../MasterComponents/Grid/GridRow';
import GridCol from '../../MasterComponents/Grid/GridCol';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
import { showModal } from '../../MasterComponents/Modals/ModalsActions';
import ManageUserModal from './Modals/ManageUserModal';
import axios from 'axios';
import DashboardSettingsBanner from './DashboardSettingsBanner';

class DashboardSettings extends Component {
  constructor(props) {
    super(props);    
    this.state = {
      users: [],
      locations: [],
      defaultTitle: 'Read Only',      
      showUsersSection: false
    }
  }
  
componentDidMount = () => {
    const { dispatch } = this.props;
    const section= {
      button: 'Settings',
      title: 'Settings'
    };
    dispatch(changePage(section));
    this.initialLoad();
}

initialLoad = () => {
    const { dispatch } = this.props;
    const { defaultTitle } = this.state;
    const showUsersSection = (defaultTitle !== localStorage.getItem('title')) ? true : false;
    
    dispatch(spinner(true));
    axios.all([DashboardApi.getUsers(), DashboardApi.getMerchants()]).then(axios.spread((usr, merch) => {
        dispatch(spinner(false));
        const users = usr.data.data;
        const locations = merch.data.data;        
        this.setState({
            users: users,
            locations: locations,
            showUsersSection: showUsersSection
        });
        dispatch(saveUsers(users));
        dispatch(saveMerchants(locations));
    }));
}

changePassword = (e) => {
  e.preventDefault();
  const { dispatch, refresh} = this.props;
  dispatch(showModal(ManageUserModal, 'manageUserModal', {header:'Change Password', type:'changePassword', refresh: refresh}));
}

manageUsers = (e) => {
  e.preventDefault();
  const { dispatch, refresh} = this.props;
  dispatch(showModal(ManageUserModal, 'manageUserModal', {header:'Users and Roles', type:'usersAndRoles', refresh: refresh}));
}

logout = (e) => {
  e.preventDefault();
  this.props.history.push('/logout');
}

render() {
    const {showUsersSection} = this.state;    
    if (isMobile){
      return (
        <Fragment>          
          <GridRow className='mt-4 border-bottom'>
            <GridCol className='form-group' onClick={this.changePassword}>
              <InputLabel title text='Change Password'/>
            </GridCol>
          </GridRow>

          {
            (!showUsersSection) ? null :
            <GridRow className='mt-4 border-bottom'>
              <GridCol className='form-group' onClick={this.manageUsers}>
                <InputLabel title text='Users and Roles'/>
              </GridCol>
            </GridRow>
          }
          
          <GridRow className='mt-4 border-bottom'>
            <GridCol className='form-group' onClick={this.logout}>
              <InputLabel title text='Log Out'/>
            </GridCol>
          </GridRow>
        </Fragment>
      );
    }else{
      if (showUsersSection){
        if (this.props.companyId !== 12 && this.props.companyId !== 14) {
          return (
            <Fragment>
                { this.props.companyId === 1 &&
                  <DashboardSettingsBanner />
                }
                <Tabs id='dashboard-settings' className='tab my-5'>
                  <div label='Change Password' component={<DashboardSettingsChangePassword/>}/>
                  <div label='Users and Roles' component={<DashboardSettingsUsers refresh={this.initialLoad} {...this.props} />}/>
                </Tabs>
            </Fragment>
          );
        }
        return (
          <Fragment>
              <Tabs id='dashboard-settings' className='tab my-5'>
                <div label='Change Password' component={<DashboardSettingsChangePassword/>}/>
                <div label='' component={<div/>}/>
              </Tabs>
          </Fragment>
        );
      }else{
        return (
          <Fragment>
              <Tabs id='dashboard-settings' className='tab my-5'>
                  <div label='Change Password' component={<DashboardSettingsChangePassword/>}/>
                  <div label='' component={<div/>}/>
              </Tabs>
          </Fragment>
        );
      }
      
    }    
    
}

};

const mapStateToProps = state => {
  return {
    section: state.dashboard.section,
    users: state.dashboard.users,
    locations: state.dashboard.merchants,
    companyId: state.company.id
  };
}

export default connect(mapStateToProps)(DashboardSettings);
