import React, { Component } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import { BsArrowDown } from 'react-icons/bs';
import moment from 'moment';
import SearchInput from '../../MasterComponents/SearchInput/SearchInput';
import InputLabel from '../../MasterComponents/InputLabel/InputLabel';
export default class LoginAdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchUser: ''
    }
  }

  onLogin = (e, user) => {
    this.props.login(e, user);
  };

  onLogout = (e) => {
    this.props.logout(e);
  }

  onChangeHandler = (value, name) => {
    this.setState({
      [name]: value.replace(/([.?*+^$[\]\\(){}|-])/g, "")
    });
  }

  render() {
    const { users } = this.props;
    const { searchUser } = this.state;
    const Header = (
      <Row className='border text-size-13 py-2 text-grey'>
        <Col xs={2}>
          USERNAME <BsArrowDown size={8} />
        </Col>
        <Col xs={3}>
          EMAIL <BsArrowDown size={8} />
        </Col>
        <Col xs={2}>
          #OF LOCATIONS <BsArrowDown size={8} />
        </Col>
        <Col xs={2}>
          CREATED DATE <BsArrowDown size={8} />
        </Col>
        <Col xs={1} className='px-0'>
          ACTIVATED <BsArrowDown size={8} />
        </Col>
        <Col xs={1}>
          ROLE <BsArrowDown size={8} />
        </Col>
        <Col xs={1}>
        </Col>
      </Row>
    );
    const usersMap = users.filter((u)=>u.value.name.toLowerCase().indexOf(searchUser.toLowerCase()) !== -1).map((u, i) => (
      <Row key={`user_${i}`} className='border text-size-14 d-flex align-items-center py-1'>
        <Col xs={2}>
          {u.value.name}
        </Col>
        <Col xs={3}>
          {u.value.email}
        </Col>
        <Col xs={2}>
          {u.value.numberOfLocations}
        </Col>
        <Col xs={2}>
          {moment(u.value.createdDate).format('MM/DD/YYYY')}
        </Col>
        <Col xs={1}>
          {u.value.isActive === 1 ? 'Yes' : 'No'}
        </Col>
        <Col xs={1}>
          {u.value.role}
        </Col>
        <Col xs={1} className='px-0'>
          <Button active block  className='py-2 shadow-none text-size-12' size='xs' color='primary' onClick={(e)=>this.onLogin(e, u)} style={{width:'80px', height:'35px'}} >LOGIN</Button>
        </Col>
      </Row>
    ));
    const User = (
      <Row className='d-flex pb-3'>
        <Col xs={5} className='pl-0' style={{zIndex: 0}}>
          <InputLabel text='Users' title className='text-size-24 mb-1' />
          <SearchInput name='searchUser' placeholder='Search' value={searchUser} onChange={this.onChangeHandler} />
        </Col>
        <Col xs={6} />
        <Col xs={1} className='justify-content-end px-0 pb-3 pt-4 mt-2'>
          <Button active block  className='py-2 shadow-none text-size-12' size='xs' color='primary' onClick={(e)=>this.onLogout(e)}>LOGOUT</Button>
        </Col>
      </Row>
    );
    if(users.length === 0) {
      return (
        <Container fluid className='mx-5 mb-4'>
          {User}
          {Header}
          <Row className='border'>
            <Col className='text-center py-3'>
              No Users to Display
            </Col>
          </Row>
        </Container>
      )
    }
    return (
      <Container fluid className='mx-5 mb-4'>
        {User}
        {Header}
        {usersMap}
      </Container>
    )
  }
}
